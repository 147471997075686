import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import TurnPhoneH from '../other/TurnPhoneH'
import { useSelector } from 'react-redux'
import isAuthenticated from '../auth/auth'

import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next'
import Modal from './Modal'

import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export default function SuccessSkillDetails() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);
    const jobs = useSelector((state) => state.job.jobData)
    const [open, setOpen] = useState(false)

    const { id: jobID } = useParams()

    const [jobDetail, setJobDetail] = useState([])

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);

    useEffect(() => {
        setJobDetail(jobs.filter(job => { return (job.id == jobID) }))
    }, [jobs])


    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side for Success Skills" />)
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <Modal open={open} setOpen={setOpen} />
                <div className="container-success-details">
                    <div className="header">
                        {
                            window.innerWidth < 768 ?
                                <img src="../assets/svg/skills/skill-detail.svg" alt="" />
                                :
                                <img src="../assets/svg/skills/skill-details-head1.svg" alt="" />
                        }

                        <div className="btn-back-home bg-white" style={{ top: '11px', left: '16px' }} onClick={() => {
                            log({
                                pageName: 'Success Skill Details',
                                buttonName: 'Back'
                            })
                            window.history.go(-1)
                        }}>
                            <KeyboardBackspaceRoundedIcon style={{ fontSize: '18px', color: '#181818' }} />
                        </div>
                    </div>
                    <div className="content">
                        <div className="top-head">
                            <h3>{jobDetail[0]?.name}</h3>
                            <button
                                onClick={() => log({
                                    pageName: 'Success Skill List',
                                    buttonName: 'Business'
                                })}
                            >BUSINESS</button>
                        </div>
                        <div className="inner-content">
                            <h5>
                                {t("successSkillsDetails.description")}
                            </h5>
                            <p>{jobDetail[0]?.description}</p>

                        </div>
                        <div className="details-form-container m-0" style={{ width: "100%" }}>
                            <div className="row">
                                {
                                    jobDetail[0]?.skills.map((skill, index) => {
                                        return (
                                            <div key={index} className="col-4 btn-skills">{skill}</div>
                                        )
                                    })
                                }
                            </div>

                        </div>


                        <div className="inner-content">
                        </div>
                        <button className="btn-request-learning"

                            onClick={() => {
                                setOpen(true)
                                log({
                                    pageName: 'Success Skill List',
                                    buttonName: 'Request  Learning Roadmap'
                                })
                            }}
                        >{t("successSkillsDetails.request")}</button>
                    </div>
                </div>
            </>
        )
}
