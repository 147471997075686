import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "gotCode": {
        "skip": "Skip",
        "GotCode": "Got a Code ?",
        "placeholder": "Enter code here",
        "btnNext": "Next",
        "btnReq": "Request Code",
        "langChange": "Language",
        "langEng": "English",
        "langSp": "Spanish",
        "langHt": "Haitian Creole",
      },
      "welcome": {
        "beforeBr": "Your Financial Education",
        "afterBr": "and Digital Wallet",
        "btnLearnMore": "Learn More",
        "btnSkip": "Skip Tour"
      },
      "learnMore": {
        "beforeBr1": "Guide to Personal Finance",
        "afterBr1": "and Self Reliance",
        "2": "Understanding Business Foundation",
        "3": "Becoming a Business Entrepreneur",
        "btnGo": "Go"
      },
      "getStarted": {
        "btnGetStarted": "Get Started",
        "btnLogin": "Login",
        "btnForgotPassword": "Forgot Password ?",
        "btnCustomerSupport": "Customer Support",
        "copyRight": "2023 Copyright Stickball Technology Inc. All Rights Reserved Legal"
      },
      "login": {
        "login": "Login",
        "userName": "UserName/Email",
        "password": "Password",
        "forgotPassword": "Forgot Password ?",
        "register": "Register Now",
        "dontHaveAccount": "Don't have an account?"
      },
      "fullRegister": {
        "email": "Email",
        "dob": "DOB",
        "phoneNumber": "Phone Number",
        "zipCode": "Zip Code",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "btnNext": "Next",
      },
      "codeRegister": {
        "email": "Email",
        "dob": "DOB",
        "zipCode": "Zip Code",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "btnSignUp": "Sign Up",
      },
      "requestCode": {
        "beforeBr": "Our Team ",
        "afterBr": "is working on it!",
        "firstName": "First Name",
        "lastName": "Last Name",
        "student": "Student",
        "employer": "Employer",
        "email": "Email",
        "btnRequestCode": "Request Code",
      },
      "resetPassword": {
        "resetPassword": "Reset Password",
        "beforeBr": "Let’s Reset",
        "afterBr": "Your Password",
        "email": "Email",
        "verificationCode": "Enter your verification code",
        "newPassword": "New Password",
        "confirmPassword": "Confirm Password",
        "sendCode": "Send Code",
        "done": "Done",
        "dontHaveAccount": "Don't have an account ?",
        "register": "Register Here",
      },
      "emailVerification": {
        "label": "Enter Code",
        "placeholder": "Enter code from email",
        "btnNext": "Next",
        "btnResend": "Resend Code (1 min)",
      },
      "loading": {
        "text": "We're getting things ready for you"
      },
      "module": {
        "btnStarted": "Get Started",
        "btnLearn": "Learn More",
        "btnSuccess": "Success Skills",
        "btnLogout": "Logout"
      },
      "turn": {
        "gamemode": "Turn your phone to the side for game mode"
      },
      "wordleGame": {
        "turn": "Turn your phone for Wordle Game",
        "headSub": "Find the word 6 tries!",
        "error": "Please First Complete The Lesson Then Try To Play The Game!",
        "btnSubmit": "Submit Word",
        "modalHead": "How to Play ?",
        "findByClues": "Find the words by using clues",
        "blue": "Blue",
        "orange": "Orange",
        "darkGrey": "Dark Grey",
        "correct": "Correct",
        "wrongPlace": "Wrong Place",
        "incorrect": "Incorrect",
        "oneLetter": "One letter to go!",
        "goodLuck": "Good Luck!"
      },
      "profile": {
        "turn": "Turn your phone to side for profile",
        "setting": "Settings",
        "general": "General",
        "badges": "Badges",
        "firstName": "First Name",
        "lastName": "Last Name",
        "email": "Email",
        "dob": "DOB",
        "organization": "Organization",
        "phoneNumber": "Phone Number",
        "zipCode": "Zip Code",
        "privacyPolicy": "Privacy Policy",
        "tapToGo": "Tap to see Privacy Policy",
        "logout": "Logout",
        "edit": "Edit",
        "save": "Save"
      },
      "feedback": {
        "howDoYouDo": "How Did You Do ?",
        "btnSubmit": "Submit"
      },
      "quizResult": {
        "quizResults": "Quiz Results",
        "completed": "Completed",
        "pointsEarned": "Points Earned",
        "congratulationsOnCompletion": "Congratulations on completion",
        "btnNext": "Next",
      },
      "Print Page": 'Print Page',
      "askAnything": "Ask Anything",
      online: "Online",
      enterQuestion: "Enter Question",
      typing: "typing",
      disclaimerText: `Disclaimer: Information on this website is made available to you only as self-help tools for your independent use and is not intended to provide financial or tax advice. We cannot and do not guarantee its applicability or accuracy in regard to your individual circumstances. All examples are for illustrative purposes only. We encourage you to seek personalized advice from qualified professionals regarding all personal or business finance issues.`,
      grade: "Grade",
      gradeLevel: "Grade Level",
      gradeUpdatedMessage: "Grade level updated to",
      emptyChatText1: `Ask Anything helps you with financial questions related to our course content. Simply type your question in the chat below, and an answer will appear. For questions about your Brookline Bank accounts or services, please contact a representative at 877-668-2265.`,
      emptyChatText2: `If you have questions about your Brookline Bank accounts or services, please speak with a representative at 877-668-2265.`,
      emptyChatText3: `It's easy and quick!`,
      retryQuiz: "Retry Quiz",
      help: "Help",
      needHelp: "Need Help?",
      supportMail: "For technical support with Stickball course content, please email:",
      supportExplanation: "Please provide an explanation of the issue you are having as well as the following information below as part of your support request:",
      helpOption1: "Operating System: Windows",
      helpOption2: "Browser: Mozilla/5.0 (Windows NT 10.0; Win64;x64) AppleWebKit/537.36 (KHTML, Like Gecko) Chrome/125.0.0.0 Safari/537.36",
      helpOption3: "Device: Win32",
      ok: "OK",
      popupText1: "If you clear your browser cache, you will lose all the progress you’ve made and information you have entered in the Financial Education Center. To avoid this, please refrain from clearing your cache.",
      popupText2: "For guidance in the Financial Education Center, click the “?” button in the bottom left to view a demonstration. This will show you how to navigate and make the most of each section.",
      attention: "Attention",
      beforeYouBegin: "Before You Begin",
      "successSkillsIntro": {
        "turn": "Turn your phone to side for success skills",
        "doYouHaveTheSkills": "Do you have the skills to be",
        "btnGetStarted": "Get Started"
      },
      "successSkillsForm": {
        "oldJob": "Select Current or Similar Job Title",
        "jobPlaceholder": "Choose job title here",
        "newJob": "Select Desired Job Title",
        "city": "Select City or State",
        "go": "Go",
        "addSkill": "Add Skills",
        "add": "Add",
        "addMoreSkills": "Add more skills",
        "search": "Searching the Universe One Moment"
      },
      "successSkillsList": {
        "recomendedJob": "Recommended Job Titles",
        "btnLearn": "Learn More",
        "meet1": "Schedule a Meeting",
        "meet2": "with a Career Mentor",
        "otherJob": "Other Job Titles"
      },
      "successSkillsDetails": {
        "description": "Description",
        "request": "Request  Learning Roadmap"
      },
      "privacyPolicy": {
        "turn": "Turn your phone to side for privacy policy",
        "privacyHeading": "Privacy Policy",
        "privacyText": "Education built the Educate Live app as a Commercial app. This SERVICE is provided by Education and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.",
        "cookiesHeading": "Cookies",
        "cookiesText": " Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.",
        "securityHeading": "Security",
        "securityText": " We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.",
        "childerenHeading": "Children’s Privacy",
        "childerenText": " These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.",
        "contactUsHeading": "Contact Us",
        "contactUsText": " If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at [App contact information].",
        "illusHeading": "Illustration By",
        "illusText": "https://stories.freepik.com",

      },
      "customerSuport": {
        "heading": "Customer Suport",
        "Email": "Email",
        "EnterEmail": "Enter Email",
        "IssueTitle": "Issue Title",
        "placeholderTitle": "Enter issue title",
        "IssueDescription": "Issue Description",
        "placeholderDescription": "Describe the issue in details",
        "uploadBtn": "Upload screenshots of issues",
        "Submit": "Submit",
      },
      "Print Page": 'Print Page'
    }
  },
  sp: {
    translation: {
      "gotCode": {
        "skip": "Patrón",
        "GotCode": "¿Tienes un código?",
        "placeholder": "Introduzca el código aquí",
        "btnNext": "Siguiente",
        "btnReq": "Código de solicitud",
        "langChange": "Idioma",
        "langEng": "Inglés",
        "langSp": "Español",
        "langHt": "criollo haitiano",
      },
      "welcome": {
        "beforeBr": "Su educación financiera",
        "afterBr": "y Digital ",
        "btnLearnMore": "Más información",
        "btnSkip": "Ir al tour"
      },
      "learnMore": {
        "beforeBr1": "Guía de Finanzas Personales",
        "afterBr1": "y autosuficiencia",
        "2": "Comprensión de la base empresarial",
        "3": "Convertirse en empresario de negocios",
        "btnGo": "Vamos"
      },
      "getStarted": {
        "btnGetStarted": "Empieza",
        "btnLogin": "Iniciar sesión",
        "btnForgotPassword": "¿Olvidó la contraseña?",
        "btnCustomerSupport": "Atención al cliente",
        "copyRight": "2023 derechos de autor Stickball Technology Inc. Todos los derechos reservados"
      },
      "login": {
        "login": "Iniciar sesión",
        "userName": "Nombre del usuario / Email",
        "password": "Contraseña",
        "forgotPassword": "¿Olvidó la contraseña?",
        "register": "Registro",
        "dontHaveAccount": "¿No tienes cuenta?"
      },
      "fullRegister": {
        "email": "Email",
        "dob": "Fecha de nacimiento",
        "phoneNumber": "Número de teléfono",
        "zipCode": "Código postal",
        "password": "Contraseña",
        "confirmPassword": "Confirmar contraseña",
        "btnNext": "Siguiente",
      },
      "codeRegister": {
        "email": "Email",
        "dob": "Fecha de nacimiento",
        "zipCode": "Código postal",
        "password": "Contraseña",
        "confirmPassword": "Confirmar contraseña",
        "btnSignUp": "Regístrate",
      },
      "requestCode": {
        "beforeBr": "Nuestro administrador se pondrá en ",
        "afterBr": "contacto  con usted pronto!",
        "firstName": "Nombre",
        "lastName": "Apellido",
        "student": "Estudiante",
        "employer": "Empleador",
        "email": "Email",
        "btnRequestCode": "Código de solicitud",
      },
      "resetPassword": {
        "resetPassword": "Reiniciar contraseña",
        "beforeBr": "Reiniciar",
        "afterBr": "Su contraseña",
        "email": "Email",
        "verificationCode": "Introduzca su código de verificación",
        "newPassword": "Nueva contraseña",
        "confirmPassword": "Confirmar contraseña",
        "sendCode": "Código",
        "done": "Hecho",
        "register": "Registro",
        "dontHaveAccount": "¿No tienes cuenta?"
      },
      "emailVerification": {
        "lebel": "Código",
        "placeholder": "Introduzca el código del correo electrónico",
        "btnNext": "Siguiente",
        "btnResend": "Código de Resolucion (1 min)",
      },
      "loading": {
        "text": "Estamos preparando las cosas para ti."
      },
      "module": {
        "btnStarted": "Empieza",
        "btnLearn": "Más información",
        "btnSuccess": "Habilidades de éxito",
        "btnLogout": "Cerrar sesión"
      },
      "turn": {
        "gamemode": "Gire el teléfono al lado para el modo de juego"
      },
      "wordleGame": {
        "turn": "Gire su teléfono para Wordle Game",
        "headSub": "¡Encuentra la palabra 6 intentos!",
        "error": "Por favor, primero complete la lección y luego trate de jugar el juego!",
        "btnSubmit": "Enviar palabra",
        "modalHead": "¿Cómo Jugar?",
        "findByClues": "Encontrar las palabras utilizando pistas",
        "blue": "Azul",
        "orange": "Naranja",
        "darkGrey": "Gris oscuro",
        "correct": "Correcto",
        "wrongPlace": "Lugar equivocado",
        "incorrect": "Incorrecto",
        "oneLetter": "¡Una carta para ir!",
        "goodLuck": "¡Buena suerte!"

      },
      "profile": {
        "turn": "Gire el teléfono al lado para perfil",
        "setting": "Ajustes",
        "general": "General",
        "badges": "Insignias",
        "firstName": "Nombre",
        "lastName": "Apellido",
        "email": "Email",
        "dob": "Fecha de nacimiento",
        "organization": "Organización",
        "phoneNumber": "Número de teléfono",
        "zipCode": "Código postal",
        "privacyPolicy": "Política de privacidad",
        "tapToGo": "Pulse para ver la política de privacidad",
        "logout": "Cerrar sesión",
        "edit": "Editar",
        "save": "Guardar"
      },
      "feedback": {
        "howDoYouDo": "¿Cómo estás?",
        "btnSubmit": "Presentar"
      },
      "quizResult": {
        "quizResults": "Resultados de Quiz",
        "completed": "Completado",
        "pointsEarned": "Puntos pendientes",
        "congratulationsOnCompletion": "Felicitaciones por completar",
        "btnNext": "Siguiente",
      },
      "successSkillsIntro": {
        "turn": "Gire su teléfono a su lado para las habilidades de éxito",
        "doYouHaveTheSkills": "¿Tienes las habilidades para ser",
        "btnGetStarted": "Empieza"
      },
      "successSkillsForm": {
        "oldJob": "Seleccione Título de trabajo actual o similar",
        "jobPlaceholder": "Elija el título de trabajo aquí",
        "newJob": "Seleccione el título de trabajo deseado",
        "city": "Seleccione Ciudad o Estado",
        "go": "Vamos",
        "addSkill": "Añadir Habilidades",
        "add": "Añadir",
        "addMoreSkills": "Más habilidades",
        "search": "Búsqueda del Momento del Universo"
      },
      "successSkillsList": {
        "recomendedJob": "Títulos de trabajo recomendados",
        "btnLearn": "Más información",
        "meet1": "Programa una reunión",
        "meet2": "con un mentor profesional",
        "otherJob": "Otros títulos de trabajo"
      },
      "successSkillsDetails": {
        "description": "Descripción",
        "request": "Solicitud de guía de aprendizaje"
      },
      "privacyPolicy": {
        "turn": "Gire su teléfono al lado para la política de privacidad",
        "privacyHeading": "Política de privacidad",
        "privacyText": "La educación construyó la aplicación Educate Live como aplicación comercial. Este SERVICIO es proporcionado por Educación y está destinado al uso como es. Esta página se utiliza para informar a los visitantes acerca de nuestras políticas con la colección, uso y divulgación de Información Personal",
        "cookiesHeading": "Cookies",
        "cookiesText": "Las cookies son archivos con una pequeña cantidad de datos que se utilizan comúnmente como identificadores únicos anónimos. Estos son enviados a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.",
        "securityHeading": "Seguridad",
        "securityText": "Valoramos su confianza en proporcionarnos su Información Personal, por lo que estamos tratando de utilizar medios comercialmente aceptables para protegerla. Pero recuerde que ningún método de transmisión a través de Internet, o método de almacenamiento electrónico es 100% seguro",
        "childerenHeading": "Privacidad de los niños",
        "childerenText": " Estos Servicios no abordan a nadie menor de 13 años. No recopilamos a sabiendas información personal identificable de niños menores de 13 años. En el caso de descubrir que un niño menor de 13 años nos ha proporcionado información personal, inmediatamente",
        "contactUsHeading": "Contáctenos",
        "contactUsText": "Si tiene alguna pregunta o sugerencia sobre nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros en [Aplicar información de contacto].",
        "illusHeading": "Ilustración por",
        "illusText": "https://stories.freepik.com",

      },
      "termsConditions": {
        "heading": "Terms and Conditions",

      },
      "customerSuport": {
        "heading": "Atención al cliente",
        "Email": "Correo electrónico",
        "EnterEmail": "Ingrese correo electrónico",
        "IssueTitle": "Título del problema",
        "placeholderTitle": "Ingrese el título del problema",
        "IssueDescription": "descripcion del problema",
        "placeholderDescription": "Describa el problema en detalle",
        "uploadBtn": "Subir capturas de pantalla del problema",
        "Submit": "Entregar",
      },
      "Print Page": 'Imprimir página'
    }
  },
  ht: {
    translation: {
      "gotCode": {
        "skip": "Sote",
        "GotCode": "Èske w gen yon kòd?",
        "placeholder": "Antre kòd isit la",
        "btnNext": "Next",
        "btnReq": "Mande Kòd",
        "langChange": "Lang",
        "langEng": "angle",
        "langSp": "Panyòl",
        "langHt": "Kreyòl ayisyen",
      },
      "welcome": {
        "beforeBr": "Edikasyon Finansye w la",
        "afterBr": "ak Digital Wallet",
        "btnLearnMore": "Aprann plis",
        "btnSkip": "Sote vwayaj"
      },
      "learnMore": {
        "beforeBr1": "Gid pou Finans Pèsonèl",
        "afterBr1": "ak Self Reliance",
        "2": "Konprann Fondasyon Biznis",
        "3": "Vin yon antreprenè biznis",
        "btnGo": "Ale"
      },
      "getStarted": {
        "btnGetStarted": "Kòmanse",
        "btnLogin": "Ouvri sesyon an",
        "btnForgotPassword": "Ou Bliye Modpas?",
        "btnCustomerSupport": "Sipò kliyan",
        "copyRight": "2023 Dwa Stickball Technology Inc. Tout Dwa Rezève Legal"
      },
      "login": {
        "login": "Ouvri sesyon an",
        "userName": "Non itilizatè/imel",
        "password": "Modpas",
        "forgotPassword": "Ou Bliye Modpas?",
        "register": "Enskri kounye a",
        "dontHaveAccount": "Ou pa gen yon kont?"
      },
      "fullRegister": {
        "email": "Imèl",
        "dob": "Dat nesans",
        "phoneNumber": "Nimewo telefòn",
        "zipCode": "Kòd postal",
        "password": "Modpas",
        "confirmPassword": "Konfime modpas",
        "btnNext": "Next",
      },
      "codeRegister": {
        "email": "Imèl",
        "dob": "dat nesans",
        "zipCode": "Kòd postal",
        "password": "Modpas",
        "confirmPassword": "Konfime modpas",
        "btnSignUp": "Enskri",
      },
      "requestCode": {
        "beforeBr": "Ekip nou an",
        "afterBr": "ap travay sou li!",
        "firstName": "Premye Non",
        "lastName": "Siyati",
        "student": "Elèv",
        "employer": "Anplwayè",
        "email": "Imèl",
        "btnRequestCode": "Mande Kòd",
      },
      "resetPassword": {
        "resetPassword": "Reyajiste modpas",
        "beforeBr": "Ann Reset",
        "afterBr": "Modpas ou",
        "email": "Imèl",
        "verificationCode": "Antre kòd verifikasyon ou a",
        "newPassword": "Nouvo modpas",
        "confirmPassword": "Confirm Password",
        "sendCode": "Voye Kòd",
        "done": "Fè",
        "dontHaveAccount": "Don't have an account ?",
        "register": "Enskri isit la",
      },
      "emailVerification": {
        "label": "Antre Kòd",
        "placeholder": "Antre kòd ki soti nan imel",
        "btnNext": "Next",
        "btnResend": "Revoye kòd (1 min)",
      },
      "loading": {
        "text": "Nou ap prepare bagay yo pou ou"
      },
      "module": {
        "btnStarted": "Kòmanse",
        "btnLearn": "Aprann plis",
        "btnSuccess": "Konpetans siksè",
        "btnLogout": "Dekonekte"
      },
      "turn": {
        "gamemode": "Vire telefòn ou sou bò pou mòd jwèt"
      },
      "wordleGame": {
        "turn": "Vire telefòn ou pou jwèt Wordle",
        "headSub": "Jwenn mo 6 eseye!",
        "error": "Tanpri dabò ranpli leson an answit eseye jwe jwèt la!",
        "btnSubmit": "Soumèt Pawòl",
        "modalHead": "Kòman pou jwe ?",
        "findByClues": "Jwenn mo yo lè l sèvi avèk endikasyon",
        "blue": "Ble",
        "orange": "zoranj",
        "darkGrey": "Gri nwa",
        "correct": "Kòrèk",
        "wrongPlace": "Move Kote",
        "incorrect": "Pa kòrèk",
        "oneLetter": "Yon lèt pou ale!",
        "goodLuck": "Bon chans!"
      },
      "profile": {
        "turn": "Vire telefòn ou sou kote pou pwofil la",
        "setting": "Anviwònman",
        "general": "Jeneral",
        "badges": "Badj",
        "firstName": "Premye Non",
        "lastName": "Siyati",
        "email": "Imèl",
        "dob": "dat nesans",
        "organization": "Òganizasyon",
        "phoneNumber": "Nimewo telefòn",
        "zipCode": "Kòd postal",
        "privacyPolicy": "Règleman sou enfòmasyon prive",
        "tapToGo": "Tape pou wè Règleman sou enfòmasyon prive",
        "logout": "Dekonekte",
        "edit": "Edit",
        "save": "Sove"
      },
      "feedback": {
        "howDoYouDo": "Kijan ou te fè?",
        "btnSubmit": "Soumèt"
      },
      "quizResult": {
        "quizResults": "Rezilta egzamen",
        "completed": "Konplete",
        "pointsEarned": "Pwen ou touche",
        "congratulationsOnCompletion": "Felisitasyon pou fini",
        "btnNext": "Next",
      },
      "successSkillsIntro": {
        "turn": "Vire telefòn ou sou kote pou gen ladrès siksè",
        "doYouHaveTheSkills": "ou gen konpetans yo dwe",
        "btnGetStarted": "Kòmanse"
      },
      "successSkillsForm": {
        "oldJob": "Chwazi Tit Travay Aktyèl oswa Menm jan an",
        "jobPlaceholder": "Chwazi tit travay isit la",
        "newJob": "Chwazi Tit travay ou vle",
        "city": "Chwazi Vil oswa Eta",
        "go": "Ale",
        "addSkill": "Ajoute Ladrès",
        "add": "Ajoute",
        "addMoreSkills": "Ajoute plis konpetans",
        "search": "Chèche Linivè Yon Moman"
      },
      "successSkillsList": {
        "recomendedJob": "Tit Travay Rekòmande",
        "btnLearn": "Aprann plis",
        "meet1": "Pwograme yon Reyinyon",
        "meet2": "ak yon Mentor Karyè",
        "otherJob": "Lòt Tit travay"
      },
      "successSkillsDetails": {
        "description": "Deskripsyon",
        "request": "Mande Roadmap Aprantisaj"
      },
      "privacyPolicy": {
        "turn": "Vire telefòn ou sou kote pou règleman sou enfòmasyon prive",
        "privacyHeading": "Règleman sou enfòmasyon prive",
        "privacyText": "Edikasyon te bati app Educate Live la kòm yon aplikasyon Komèsyal. Se Edikasyon ki founi SÈVIS sa a epi li fèt pou l itilize jan li ye a. Yo itilize paj sa a pou enfòme vizitè yo konsènan règleman nou yo ak koleksyon, itilizasyon ak divilgasyon Enfòmasyon Pèsonèl si yon moun deside sèvi ak Sèvis nou an.",
        "cookiesHeading": "Bonbon",
        "cookiesText": " Bonbon yo se dosye ki gen yon ti kantite done ki souvan itilize kòm idantifyan inik anonim. Yo voye sa yo nan navigatè ou a soti nan sit entènèt ou vizite yo epi yo estoke nan memwa entèn aparèy ou an.",
        "securityHeading": "Sekirite",
        "securityText": " Nou apresye konfyans ou nan bay nou Enfòmasyon Pèsonèl ou, kidonk nou ap fè efò pou sèvi ak mwayen komèsyal akseptab pou pwoteje li. Men, sonje ke pa gen okenn metòd transmisyon sou entènèt la, oswa metòd depo elektwonik se 100% an sekirite ak serye, epi nou pa ka garanti sekirite absoli li yo.",
        "childerenHeading": "Konfidansyalite Timoun yo",
        "childerenText": " Sèvis sa yo pa adrese pèsonn ki poko gen laj 13 an. Nou pa fè espre kolekte enfòmasyon pèsonèl ki idantifye nan men timoun ki poko gen 13. Nan ka a nou dekouvri ke yon timoun ki poko gen 13 te bay nou enfòmasyon pèsonèl, nou imedyatman efase sa a nan sèvè nou yo. Si ou se yon paran oswa gadyen epi ou konnen pitit ou a te bay nou enfòmasyon pèsonèl, tanpri kontakte nou pou nou kapab fè aksyon ki nesesè yo.",
        "contactUsHeading": "Kontakte nou",
        "contactUsText": " Si w gen nenpòt kesyon oswa sijesyon sou Règleman sou enfòmasyon prive nou an, pa ezite kontakte nou nan [ App contact information ].",
        "illusHeading": "Ilistrasyon pa",
        "illusText": "https://stories.freepik.com",

      },
      "customerSuport": {
        "heading": "Sipò kliyan",
        "Email": "Imèl",
        "EnterEmail": "Antre Imèl",
        "IssueTitle": "Tit Pwoblèm",
        "placeholderTitle": "Antre tit pwoblèm lan",
        "IssueDescription": "Deskripsyon pwoblèm",
        "placeholderDescription": "Dekri pwoblèm nan an detay",
        "uploadBtn": "Voye Ekran sou pwoblèm yo",
        "Submit": "Soumèt",
      },
      "Print Page": 'Enprime paj'
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || "en",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;