const config = {
    palette: {
        primary: {
            main: "#616DED",
            light: "#9fa7fc",
            dark: "#616DED",
            contrastText: '#fff',
        },
        secondary: {
            main: "#FB785C",
            light: "#fcdbd4",
            dark: "#FB785C",
            contrastText: "#fff",
        },
        light: {
            main: "#fff"
        }
    }
}

export default config