import React, { useState } from 'react';
import {
    Grid,
    Typography,
    Box
} from '@mui/material';
import moment from 'moment'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { EventsOfDayModal } from './modals'

export const DailyCalendar = ({ currentDate, filteredDropedItems, dropedItems, eventStyle, skillCardStyle, openedCardDetails }) => {
    const [openCurrentEvents, setOpenCurrentEvents] = useState(false)
    const timeSlots = [
        '', '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM',
        '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM',
        '8 PM', '9 PM', '10 PM', '11 PM'
    ];
    let events = filteredDropedItems(dropedItems, moment(currentDate))
    const Event = ({ event_color, start_date, end_date, id, event_name: name, position }) => {
        if (moment(start_date).isSame(moment(currentDate), 'day')) {
            return (
                <Box sx={{ ...eventStyle, background: event_color, top: `${(position * 35) + 50}px`, width: '100%' }}
                // onClick={() => setSelectedEvent({ color, start_date, end_date, id, name, index, position })}
                >
                    <div style={{ width: '100%' }}>
                        {name}
                    </div>

                </Box>
            )
        }
        if (end_date && moment(start_date).isBefore(currentDate) && (moment(end_date)?.isSame(moment(currentDate), 'day') || moment(end_date)?.isAfter(currentDate))) {
            return (
                <Box sx={{ ...eventStyle, background: event_color, top: `${(position * 35) + 50}px`, width: '100%' }}
                >
                    {name}
                </Box>
            )
        }

        return null
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}
                sx={dayBoxStyle}>
                <Typography sx={{ ...dayStyle, pl: '5px' }}>
                    {currentDate.format('ddd')}
                </Typography>
            </Grid>
            {
                timeSlots.map((ts, slIndex) => {
                    if (slIndex === 0) return (<>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}
                            onClick={() => setOpenCurrentEvents(true)}
                            sx={{ ...dateStyle, height: events?.length > 2 ? '180px' : events?.length > 1 ? '120px' : events?.length == 1 ? '80px' : 'fit-content' }} >
                            <Typography Typography variant='h5' sx={{
                                ...activeDateStyle,
                                background: moment(currentDate).isSame(moment(), 'day') ? '#616ded' : 'transparent',
                                color: moment(currentDate).isSame(moment(), 'day') ? '#fff' : moment(currentDate).isBefore(moment(), 'day') ? "rgba(55, 65, 81, 0.40)" : 'rgba(55, 65, 81, 0.80)',

                            }}>
                                {moment(currentDate).format('DD')}
                            </Typography >
                            {
                                events?.slice(0, 3)?.map((elem, pstn) => {
                                    return <div>
                                        <Event {...elem} position={pstn} />
                                    </div>
                                })
                            }
                            {
                                events?.length > 3 && <MoreHorizIcon style={{ position: 'absolute', bottom: 0, right: 0 }} />
                            }
                        </Grid>
                    </>)
                    return <>
                        <Grid item xs={1} sx={boxStyle}>{ts}</Grid>
                        <Grid item xs={11} sx={boxStyle} > </Grid>
                    </>
                })
            }
            {
                !!events?.length &&
                <EventsOfDayModal
                    openCurrentEvents={openCurrentEvents}
                    setOpenCurrentEvents={setOpenCurrentEvents}
                    events={events}
                    skillCardStyle={skillCardStyle}
                    openedCardDetails={openedCardDetails}
                />
            }

        </Grid >
    );
};


const boxStyle = {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '200px',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    color: '#111827',
    padding: '10px 2px',
}
const dayBoxStyle = {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
}
const dayStyle = {
    color: '#111827',
    fontSize: '14px',
    fontWeight: 600,
}

const activeDateStyle = {
    width: '40px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%'
}
const dateStyle = {
    background: '#fff',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    color: '#111827',
    padding: '10px 2px',
}