import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from '../common/NewHeader';

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMutation, useQueryClient } from 'react-query';
import { resetCode, resetPassword, userLog } from '../_api/index'
import { useTranslation } from 'react-i18next'

import { login } from '../_api/index'
import isAuthenticated from '../auth/auth'
import { Grid, Button, Typography, FormControl, TextField } from "@mui/material"
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import uuid from 'react-uuid'



export default function ResetPassword() {
    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const location = useLocation()
    const getEmail = location.state?.email
    const [serverError, setServerError] = useState(false)
    const [email, setEmail] = useState(getEmail)
    const [token, setToken] = useState("")
    const [index, setIndex] = useState(0)
    const [error, setError] = useState(false)
    const [showPass1, setShowPass1] = useState(false)
    const [showPass2, setShowPass2] = useState(false)
    const [message, setMessage] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    const OtpCode = location.state?.code


    const { isLoading: isLoading, isSuccess: isSuccess, mutate: resetEmail } = useMutation(resetCode, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            setServerError(false)
            // setIndex(index + 2)
            setMessage(`Email with Reset Password Token is sent to the Email`)
            navigate("/got-a-code", { state: { email: email } })
        },
        onError: (error) => {
            setIndex(0)
            setServerError(true)
            if (error.message === "Request failed with status code 404") {
                setMessage("User not found or user is either blocked / not confirmed")
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
            }
        }
    });


    const NOUNCE = uuid();


    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const { isLoading: passLoading, isSuccess: passSuccess, mutate: resetPass } = useMutation(resetPassword, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            // setIndex(index + 1)
            setServerError(false)
            // setMessage(`Code is sent to provided email, please check and enter the code`)
            navigate("/login")
            // navigate("/got-a-code")

        },
        onError: (error) => {
            setIndex(1)
            setServerError(true)
            if (error.message === "Request failed with status code 404") {
                setMessage("Invalid Token")
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
            }
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        if (index === 0 && !OtpCode) {
            let data = {
                email: email,
            }
            resetEmail(data)

        }
        if (OtpCode) {
            if (!error) {
                let data = {
                    email: email,
                    password: password,
                    resetPasswordToken: OtpCode,
                }
                resetPass(data)
            }
        }
    }


    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to reset your password" />)
    } else
        return (
            <>
                <Header backLink='/' logo page='Reset Password' />

                {
                    isSuccess || passSuccess && !serverError &&
                    <div className="mx-3 text-white">
                        <Alert className="alert" severity="success">
                            <h5 className="text-success">{message}</h5>
                        </Alert>
                    </div>
                }

                <Grid className="loginscreen" container sx={{ height: "100vh", padding: "8vw 14vw" }}>
                    <Grid item xs={12} md={7} container justifyContent="center" sx={{ flexDirection: "column", marginTop: { sm: "4rem", md: "0" } }}>
                        <h1 className="register--modifier">Forgot Password</h1>
                        <form onSubmit={(e) => { handleSubmit(e) }}>



                            {index <= 1 && 
                                <div className="loginscreen__email">
                                    <label className="loginscreen__label">Email Address</label>
                                    <div className="loginscreen__inputIcon ">

                                        <TextField
                                            variant="outlined"
                                            className="loginscreen__email"
                                            fullWidth
                                            id="email"
                                            placeholder={t('resetPassword.email')} required
                                            size="small"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            disabled={!!OtpCode}
                                            sx={{ borderRadius: "10px", width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    </div>
                                </div>
                            }


                            {
                                false &&
                                <div className="field ">
                                    <div className="loginscreen__password">
                                        <label className="loginscreen__label">Password</label>
                                        <div className="loginscreen__inputIcon resetpassword__field">
                                            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    size="small"
                                                    className="loginscreen__password"
                                                    onChange={(e) => {
                                                        setToken(e.target.value)
                                                    }}
                                                    placeholder={t('resetPassword.verificationCode')}
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            }




                            {
                                // index > 1 &&  
                                !!OtpCode &&
                                <>
                                    <div className="field ">
                                        <div className="loginscreen__password">
                                            <label className="loginscreen__label">Password</label>
                                            <div className="loginscreen__inputIcon resetpassword__field">
                                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                    <OutlinedInput
                                                        id="outlined-adornment-password "
                                                        type={showPassword2 ? 'text' : 'password'}
                                                        size="small"
                                                        placeholder={t('resetPassword.newPassword')}
                                                        minLength="8"
                                                        onChange={(e) => {
                                                            setPassword(e.target.value)
                                                            if (e.target.value === password2) {
                                                                setError(false)
                                                            } else {
                                                                setError(true)
                                                            }
                                                        }} required
                                                        className="loginscreen__password"
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword2}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field ">
                                        <div className="loginscreen__password">
                                            <label className="loginscreen__label">Confirm Password</label>
                                            <div className="loginscreen__inputIcon resetpassword__field">
                                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        size="small"
                                                        placeholder={t('resetPassword.confirmPassword')}
                                                        minLength="8"
                                                        // value={password2}
                                                        onChange={(e) => {
                                                            setPassword2(e.target.value)
                                                            if (e.target.value === password) {
                                                                setError(false)
                                                            } else {
                                                                setError(true)
                                                            }
                                                        }}
                                                        required
                                                        className="loginscreen__password"
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        error && <p className="text-danger error-text">Password doesn't match !</p>
                                    }
                                    {
                                        serverError &&
                                        <p className="text-danger error-text">{message}</p>
                                    }
                                </>
                            }


                            <p className="text-danger error-text">{message}</p>


                            <Button type="submit" style={{ minWidth: "70%" }} variant="contained" className="loginscreen__button loginscreen__buttonone"
                                onClick={() => {
                                    index === 1 && setIndex(index + 1)
                                    {
                                        index === 0 && !OtpCode && <>
                                            {log({
                                                pageName: 'Reset Password',
                                                buttonName: 'Send Code'
                                            })}</>
                                    }

                                    // {
                                    //     index === 1 && <>
                                    //         {log({
                                    //             pageName: 'Reset Password',
                                    //             buttonName: 'Reset Password'
                                    //         })}</>
                                    // }
                                    {
                                        !!OtpCode && <>
                                            {log({
                                                pageName: 'Reset Password',
                                                buttonName: 'Done'
                                            })}</>
                                    }
                                }}>
                                {
                                    isLoading || passLoading ?
                                        <CircularProgress style={{ color: 'white' }} />
                                        : <>
                                            {index === 0 && !OtpCode && <>{t('resetPassword.sendCode')}</>}
                                            {index === 1 && <>{t('resetPassword.resetPassword')}</>}
                                            {!!OtpCode && <>{t('resetPassword.done')}</>}
                                        </>
                                }
                            </Button>
                        </form>
                        <div className="resetpassword-paragraphs d-flex" style={{ gap: '10px', justifyContent: "flex-start" }}>
                            <p className="resetpassword-paragraphs">
                                {t('resetPassword.dontHaveAccount')}
                            </p>
                            <p className='resetpassword-paragraphs-blue'>
                                <Link to="/full-register"
                                    onClick={() => log(
                                        {
                                            pageName: 'Reset Password',
                                            buttonName: 'Register'
                                        })}
                                >
                                    <span className="resetpassword-paragraphs-blue ">{t('resetPassword.register')}</span>
                                </Link>
                            </p>
                        </div>

                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" xs={5} sx={{ display: { xs: "none", md: "flex" } }}>
                        <img className="loginscreen__illustration" src="../assets/img/fgpass.png" alt="" />
                    </Grid>
                </Grid>
            </>
        )
}






