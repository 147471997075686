import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import SkillsBar from './SkillsBar'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery } from 'react-query';
import isAuthenticated from '../../auth/auth'
import SkillSection from '../details/SkillSection'
import { userLog } from '../../_api/index'
import { setSession, userEnroll, emptyStore } from '../../../redux-features/user/userSlice'
import RewardsModal from '../details/RewardsModal'
import TranslateModal from '../details/TranslateModal'

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Notes from '../notes/Notes'

import { Grid } from "@mui/material";
//coment
import Swal from 'sweetalert2'
import GoalsModal from '../../common/GoalsModal'
import Notifications from '../../notifications'
import ChatBot from '../../common/chatbot'

function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    style={{ color: '#616ded' }}
                >{(props.value / props.divident).toFixed(0)}</Typography>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};


export default function SkillsDetails() {
    const { mutate: log } = useMutation(userLog);

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const user = useSelector((state) => state?.user?.userData?.user)
    const skillSections = useSelector(state => state.user?.skillSections)
    const skillSections2 = useSelector(state => state.user)
    const hasWaitTime = useSelector(state => state.user.hasWaitTime)
    const waitTime = useSelector(state => state.user.waitTime)
    const isNextEnabled = useSelector(state => state.user?.isNextEnabled)


    const [nextContent, setNextContent] = useState()
    const [nextContent1, setNextContent1] = useState()
    const [introContent, setIntroContent] = useState([])
    const [ind, setInd] = useState(0)
    const [open, setOpen] = useState(false)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [nextTimer, setNextTimer] = useState(waitTime)
    const [divident, setDivident] = useState(100 / waitTime)

    const [nextSrc, setNextSrc] = useState("../../assets/img/nextt.png")
    const [backSrc, setBackSrc] = useState("../../assets/img/backk.png")


    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);


    useEffect(() => {
        let temp = skillSections?.filter(ss => { return (ss?.name === "Intro") })
        let temp1 = skillSections?.filter(ss => { return (ss?.name === "Assess") })
        let temp2 = skillSections?.filter(ss => { return (ss?.name === "SIMS") })
        setNextContent(temp1)
        setNextContent1(temp2)
        setIntroContent(temp)
        if (temp.length === 0) {
            navigate("/skill-detail-assess")
        }
    }, [skillSections])
    const handleEnrollment = (data, index) => {
        if (nextContent.length === 0) {
            let fc = introContent.filter(cont => { return (!cont.isCompleted) })
            if (fc.length === 1 && !!nextContent1?.length) {
                dispatch(userEnroll(data))
                navigate("/skill-detail-sims")
            } else {
                if (introContent.length - 1 == index && !nextContent1?.length) {
                    dispatch(setSession(data))
                    navigate("/skill-detail-quiz")
                } else {
                    dispatch(userEnroll(data))
                    setNextTimer(waitTime)
                }
            }
        } else {
            let fc = introContent.filter(cont => { return (!cont.isCompleted) })
            if (!fc.length) {
                navigate("/skill-detail-assess")
            } else {
                try {
                    dispatch(userEnroll(data))
                    setNextTimer(waitTime)
                    if (introContent.length - 1 == index) {
                        navigate("/skill-detail-assess")
                    }
                } catch (err) {
                }
            }
        }
    }

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneV />)
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <RewardsModal open={open} setOpen={setOpen} />
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />

                <SkillsBar page="Skill Detail" />
                <div className="skills-details-container" style={{ overflowX: "hidden" }}>
                    <Grid container>
                        <Grid container>
                            <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem" }}>
                                {/* <img src="/assets/svg/logo.svg" style={{ width: "180px" }} /> */}
                            </Grid>
                            <Grid container xs={12} sm={4} justifyContent="flex-end">
                                <Grid item container justifyContent="flex-end" alignItems="center">

                                    <>
                                        <div className="translate-buttons" style={{ marginRight: "1rem" }}>

                                            <Link to="/" className="translate-it"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenTranslate(true)
                                                    log({
                                                        pageName: 'Alt Space-vr',
                                                        buttonName: 'Translate It'
                                                    })
                                                }}>
                                                Translate It
                                            </Link>


                                        </div>

                                    </>

                                    <Link
                                        to="/wordscape"
                                        onClick={() =>
                                            log({
                                                pageName: "Skill Details Assess",
                                                buttonName: "Word Game",
                                            })
                                        }
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}
                                    >
                                        <div style={{ display: 'flex', padding: '0.5rem' }} className="wordscape__container">
                                            <img src="../assets/svg/main-home/word-game.svg" style={{ width: "35px", marginRight: "1rem" }} alt="" />
                                            <h1 className="lessonheading">Wordle</h1>
                                        </div>
                                    </Link>

                                    <div className="logoutIcon" onClick={() => {
                                        if (sessionStorage.getItem("access") === "ok") {
                                            Swal.fire({
                                                title: '',
                                                text: "It's a teacher view, so you can't logout!",
                                                icon: 'info',
                                                confirmButtonText: 'OK'
                                            })
                                            return
                                        }
                                        dispatch(emptyStore())
                                        localStorage.removeItem("token")
window.location.reload()
                                        log({
                                            pageName: 'Module',
                                            buttonName: 'Logout Button'
                                        })
                                        navigate("/get-started")
                                    }}>
                                        <div className="logged">
                                            <div class="block">
                                                <span className="animatedIcony">
                                                    <div class="div1" >
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                    <div class="rect">
                                                        <img src="/assets/img/logout.png" />
                                                    </div>
                                                    <div class="div2">
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Notifications />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Typography varinat={'h3'} padding={"0 2rem"} sx={{ color: "#616DED", fontSize: "32px", marginTop: "1rem", fontWeight: "700" }} >
                                 {subSkillName} 
                            </Typography> */}
                        </Grid>
                    </Grid>
                    <div className="row text-center m-0 p-0">
                        {/* <h4 className="mt-3">{moduleName}</h4> */}
                    </div>
                    {

                        introContent.length === 0 ?
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <img width="30%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                            </div>
                            :
                            <>
                                {
                                    introContent.filter(content => { return (content.isCompleted) }).length === introContent.length ?
                                        <>
                                            <div className="skillsectioncontainer row justify-content-center mt-3 aaaaaa gridcontianer" style={{ margin: "0 0.5rem", width: "100%", height: "fit-content" }}>
                                                <SkillSection content={introContent[ind]} />
                                                <button className="next" onClick={() => {
                                                    log({
                                                        pageName: 'Skill Details',
                                                        buttonName: 'Next'
                                                    })
                                                    introContent[ind + 1] ? setInd(ind + 1) : navigate("/skill-detail-assess")
                                                }}>
                                                    <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                                </button>
                                            </div>

                                        </>
                                        : <></>
                                }
                            </>
                    }
                    {
                        introContent?.map((content, index) => {
                            return (
                                content?.isEnrolled && !content?.isCompleted ?
                                    <>
                                        <div key={index} className="skillsectioncontainer row justify-content-center mt-3" style={{ margin: "0 0.5rem", overflowY: "scroll", width: "100%", overflowX: "hidden" }}>
                                            <SkillSection content={content} />
                                            {
                                                nextTimer > 0 && hasWaitTime ?
                                                    <CircularProgressWithLabel style={{ color: '#616ded' }} divident={divident} value={nextTimer * divident} />
                                                    :
                                                    <button className="next"
                                                        disabled={isNextEnabled}
                                                        onClick={() => {

                                                            log({
                                                                pageName: 'Skill Details',
                                                                buttonName: 'Next'
                                                            })
                                                            handleEnrollment(content?.id, index)
                                                            // if (content?.allContent?.type === "Metaverse") {
                                                            //     setTimeout(() => {
                                                            //         window.location.reload()
                                                            //     }, 500)
                                                            // }
                                                        }}>
                                                        <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                                    </button>

                                            }
                                        </div>
                                        <div className="prev" onClick={() => {
                                            log({
                                                pageName: 'Skill Details',
                                                buttonName: 'Prev'
                                            })
                                            window.history.go(-1)
                                        }}>
                                            <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                                        </div>
                                    </> : <></>
                            )
                        })
                    }


                    <div className="prev"
                        onClick={() => {
                            log({
                                pageName: 'Skill Details',
                                buttonName: 'Prev'
                            })
                            ind === 0 ? window.history.go(-1) : setInd(ind - 1)
                        }}>
                        <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                    </div>
                </div>
                <GoalsModal />
                <ChatBot data={{ name: skillSections[ind]?.allContent?.name, description: skillSections[ind]?.allContent?.description }} pageName={"Skill Details"} />
            </>
        )
}