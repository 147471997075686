import React, { useEffect, useState } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getAssignments } from "../_api/skilll-assignments";
import LazzyLoading from "./LazzyLoading";
import PdfViewer from "../PdfViewer";
import FileViewer from "../FileViewer";
import { convertToEmbedUrl } from "../embedUtils";

export default function SkillAssignments({ type }) {
  const user = useSelector((state) => state?.user?.userData?.user);
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [showSkill, setShowSkill] = useState(false);
  const [framLink, setFramLink] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState({});

  const { isLoading, data: assignments } = useQuery(
    ["get-skill-assignments", type],
    () => getAssignments(type)
  );

  return (
    <>
      <Button
        variant="contained"
        sx={{
          mr: 4,
          background: "#fff",
          textTransform: "none",
          color: "#000",
          "&:hover": { background: "#616ded", color: "#fff" },
        }}
        onClick={() => setOpenSkillModal(true)}
      >
        Skill Assignments
      </Button>
      <Dialog
        onClose={() => setOpenSkillModal(false)}
        maxWidth="lg"
        fullWidth
        open={openSkillModal}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showSkill ? (
            <Button
              sx={{ textTransform: "none", color: "#616ded" }}
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setShowSkill(false)}
            >
              Back
            </Button>
          ) : (
            <Typography variant="h6">Skill Assignments</Typography>
          )}
          <IconButton onClick={() => setOpenSkillModal(false)}>
            <ClearRoundedIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {!showSkill ? (
            <TableContainer
              sx={{ overflowX: "hidden", overflowY: "auto", height: "65vh" }}
            >
              <Table stickyHeader aria-label="sticky table">
                {!assignments?.length ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No Assignment Found!
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                {assignments?.map((assignment, ind) => {
                  return (
                    <TableBody>
                      <TableRow hover>
                        <TableCell>{assignment?.assignmentName}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              setShowSkill(true);
                              setFramLink(
                                assignment.iframeLink + "?userID=" + user?.id
                              );
                              setIsLoaded(false);
                              setContent(assignment);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          ) : content?.type === "customForm" ? (
            <div className="px-5">
              <h1 className="text-center my-4">{content?.customFormName}</h1>
              <div className="text-center my-4">
                {isImage(content?.customFormImage) ? (
                  <div style={{ width: "80%", height: "475px", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={content?.customFormImage} alt="skill-assignment-img" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  </div>
                ) : getExtension(content?.customFormImage) === "mp4" ? (
                  <video
                    src={content?.customFormImage}
                    controls
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "80%",
                      height: "475px",
                    }}
                  />
                ) : getExtension(content?.customFormImage) === "pdf" ? (
                  <PdfViewer pdfUrl={content?.customFormImage} height="475px" />
                ) : (
                  <FileViewer
                    fileUrl={convertToEmbedUrl(content?.customFormImage)}
                    height="475px"
                  />
                )}
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: content?.customFormDescription,
                }}
              />
            </div>
          ) : (
            <>
              {!isLoaded && <LazzyLoading />}
              <iframe
                onLoad={() => setIsLoaded(true)}
                allowFullScreen="true"
                src={framLink}
                width="100%"
                height={!isLoaded ? "0px" : "65vh"}
                style={{ height: !isLoaded ? "0px" : "65vh" }}
              ></iframe>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const getExtension = (file) => {
  let splitedEl = file.split(".");
  return splitedEl[splitedEl.length - 1];
};
const isImage = (file) => {
  let extension = getExtension(file);
  return extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif";
};
