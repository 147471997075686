import React, { useState, useEffect } from "react";
import TurnPhoneV from "../other/TurnPhoneV";
import { Link, useNavigate } from "react-router-dom";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import SideBar from "./details/SideBar";
import { useSelector } from "react-redux";
import isAuthenticated from "../auth/auth";
import Loading from "../common/Loading";
import RewardsModal from "./details/RewardsModal";
import TranslateModal from "./details/TranslateModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from './notes/Notes'
import { userLog } from "../_api/index";
import { useMutation } from "react-query";
import CloseIcon from '@mui/icons-material/Close';
import Enterpreneur from './Entrepreneur.js'
import Button from '@mui/material/Button';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useDispatch } from 'react-redux';
import { emptyStore } from '../../redux-features/user/userSlice'
import { Grid } from "@mui/material"
import { useQuery } from "react-query";
import { getAllSticky } from "../_api/sticky";
import Swal from 'sweetalert2';
import GoalsModal from '../common/GoalsModal';
import TodoListModal from './notes/TodoListModal.js'
import Notifications from "../notifications/index.js";
import ChatBot from "../common/chatbot.js";

export default function Lesson(props) {


  const { mutate: log } = useMutation(userLog);
  const navigate = useNavigate();

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");


  const [nextLink, setNextLink] = useState(id);
  const [moduleContent, setModuleContent] = useState();
  const modules = useSelector((state) => state.user.userData?.data);
  const user = useSelector((state) => state?.user?.userData?.user);
  const [open, setOpen] = useState(false);
  const [openTranslate, setOpenTranslate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentLesson, setCurrentLesson] = useState('');
  const [currentSkill, setCurrentSkill] = useState('');
  const [nextSkill, setNextSkill] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [allSticky, setAllSticky] = useState('');
  const [currentLocation, setCurrentLocation] = useState({});

  const dispatch = useDispatch();

  const { isLoading, data: getSticky } = useQuery(["get-all-Stickies", 'assignment'], () => getAllSticky('assignment'));

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
      });
    } else {
      alert("Please try reloading and provide location permission to view your stickies")
    }
  }, []);




  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(0);
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  useEffect(() => {
    let stickiesOnLocation = getSticky?.data?.filter(sticky => {
      let distance = getDistanceFromLatLonInKm(currentLocation?.lat,
        currentLocation?.lng,
        sticky?.stickeyAssignmentId?.latitude,
        sticky?.stickeyAssignmentId?.longitude
      )
      if (distance <= 20) {
        return true
      } else {
        return false
      }
    })
    setAllSticky(stickiesOnLocation)
  }, [isLoading, getSticky, currentLocation])
  const openModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    modules?.map((module) => {
      if (module.id == nextLink) {
        setModuleContent(module);
        localStorage.setItem("ModuleChatbot", module?.ModuleChatbot ? JSON.stringify({ id: module?.id, status: "true" }) : "false");
        return;
      }
    });
    return 1;
  }, [modules, nextLink]);

  useEffect(() => {
    for (let i = 0; i < moduleContent?.lessons?.length; i++) {
      if (!moduleContent?.lessons[i]?.isCompleted) {
        setCurrentLesson(moduleContent?.lessons[i])
        return;
      }
    }

  }, [moduleContent])

  useEffect(() => {
    for (let i = 0; i < currentLesson?.skills?.length; i++) {
      if (!currentLesson?.skills[i]?.isCompleted) {
        setCurrentSkill(currentLesson?.skills[i])
        setNextSkill(currentLesson?.skills[i + 1])
        return;
      }
    }
  }, [currentLesson])

  setTimeout(() => {
    setShowLoader(false)
  }, 3000);

  const [isMobile, setIsMobile] = useState(false);
  function handleOrientation() {
    setIsMobile(
      window.innerWidth < 1000
    );
  }
  window.onresize = handleOrientation;
  useEffect(() => {
    setIsMobile(
      window.innerWidth < 1000
    );
  }, [window.innerWidth, window.innerHeight, window.orientation]);
  if (isMobile) {
    return <TurnPhoneV />;
  } else if (modules === undefined) {
    return (
      <>
        {showLoader && <Loading />}
        {!isAuthenticated(localStorage.getItem("token")) &&
          navigate("/login")}
      </>
    );
  } else
    return (
      <div  >
        {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
        <RewardsModal open={open} setOpen={setOpen} />
        <SideBar nextLink={nextLink} setNextLink={setNextLink} page="Lesson" />
        <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
        {
          showModal &&
          <div className="journal-modal">
            <Notes />
          </div>
        }
        <div className="lesson-container" style={{ overflow: "visible" }}>
          <Grid container>
            <Grid xs={12} sm={8} container>
              <img src="/assets/svg/logo.svg" style={{ width: "180px" }} />
            </Grid>
            <Grid container xs={12} sm={4} justifyContent="flex-end">

              <Grid item container justifyContent="flex-end">
                {
                  !!allSticky?.length &&
                  <Link to="/sticky">
                    <Button
                      sx={{ marginTop: '12px' }}
                      id="basic-button"
                    >
                      <img src="/assets/icons/notification-icon.svg" alt="" style={{ width: '37px' }} />
                    </Button>
                  </Link>
                }
                <div className="logoutIcon" onClick={() => {
                  if (sessionStorage.getItem("access") === "ok") {
                    Swal.fire({
                      title: '',
                      text: "It's a teacher view, so you can't logout!",
                      icon: 'info',
                      confirmButtonText: 'OK'
                    })
                    return
                  }
                  dispatch(emptyStore())
                  localStorage.removeItem("token")
                  window.location.reload()
                  log({
                    pageName: 'Module',
                    buttonName: 'Logout Button'
                  })
                  navigate("/get-started")
                }}>
                  <div className="logged">
                    <div class="block">
                      <span className="animatedIcony">
                        <div class="div1" >
                          <img src="/assets/img/logout1.png" />
                        </div>
                        <div class="rect">
                          <img src="/assets/img/logout.png" />
                        </div>
                        <div class="div2">
                          <img src="/assets/img/logout1.png" />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Notifications />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="myrow" style={{ paddingTop: "1rem" }}>
            <div className="text-heading">
              <div className="row d-flex justify-content-center mt-1">
                <Link
                  to="/module"
                  className="mybtn customizedbtn"
                  style={{ background: "transparent" }}
                >
                  <img src="assets/img/arrowback.png" style={{ paddingRight: ".5rem" }} />
                  Back
                </Link>
              </div>
            </div>

            <div className="translate-buttons">

              <Link to="/" className="translate-it"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTranslate(true)
                  log({
                    pageName: 'Alt Space-vr',
                    buttonName: 'Translate It'
                  })
                }}>
                Translate It
              </Link>

              <Link
                to="/rewards"
                className="completed-lessons"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                  log({
                    pageName: "Lesson",
                    buttonName: "Rewards",
                  });
                }}
              >
                <span>
                  {user?.earnPoints?.toLocaleString()} / {user?.totalPoints?.toLocaleString()}
                </span>{" "}
                <LocalAtmRoundedIcon />
              </Link>
            </div>
          </div>
          <div className="myrow" style={{ margin: "-1.5rem 0 0 0" }}>
            {
              moduleContent?.lessons.length ?
                <>
                  {
                    moduleContent?.isCompleted ?
                      <>
                        <div className="bottom_bar" style={{ margin: "0.5rem 0" }}>
                          <div className="inner_card">
                            <div className="small-card">
                              <div className="first">
                                <h2>Congratulations</h2>
                                <p>You have Conquered Stickball</p>
                              </div>
                              <div className="second">
                                <CelebrationIcon sx={{ color: "#616DED", fontSize: "50px" }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="bottom_bar" style={{ margin: "0.5rem 0" }}>
                          <div className="inner_card">
                            <div className="small-card">
                              <div className="first">
                                <h2>{currentSkill?.name}</h2>
                                <p>{nextSkill?.name}</p>
                              </div>
                              <div className="second">
                                <Button variant="outlined">
                                  <Link to={"/skills?moduleId=" + moduleContent?.id + "&lessonId=" + currentLesson.id + "&skillId=" + currentSkill?.id} >
                                    Get Started
                                  </Link>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                  }
                </>
                :
                <>
                </>
            }



          </div>
          <div
            className="lesson-content d-flex  flex-column align-items-center"
            style={{ width: "100%", height: "fit-content" }}
          >
            <Enterpreneur module={moduleContent} currentSkill={currentSkill} nextSkill={nextSkill} currentLesson={currentLesson} moduleId={moduleContent?.id} />
          </div>
          {
            sessionStorage.getItem('access') !== 'ok' &&
            <button
              onClick={openModal}
              // className="btn-journal"
              style={journalButtonStyle}
            >
              {
                showModal ?
                  <CloseIcon fontSize="large" />
                  :
                  <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#616ded', marginTop: '-2px' }} icon={faBook} />
              }
            </button>
          }
          <GoalsModal />
          <TodoListModal />
          <ChatBot
            data={moduleContent?.lessons?.map(lesson => {
              return (
                {
                  name: lesson.name,
                  skills: lesson?.skills?.map(skill => skill?.name)
                }
              )
            })}
            pageName={"Lesson"}
          />
        </div>
      </div>
    );
}

const journalButtonStyle = {
  position: 'fixed',
  width: 50,
  height: 50,
  padding: 7,
  right: 30,
  bottom: 210,
  background: '#fff',
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '50%'
}