import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from 'react-query'
import {
    Button,
    IconButton,
} from "@mui/material";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { YouTubeEmbed } from 'react-social-media-embed';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const possiblePages = [
    'full-register',
    'clever-auth',
    'email-verification',
    'reset-password',
    'login',
    'module',
    'profile',
    'get-started',
    'welcome',
    'learn-more',
    'customer-support',
    'help-check',
    'privacy-policy',
    'success-skills-intro',
    'success-skills-form',
    'success-skills-search',
    'success-skills-list',
    'success-skills-details',
    'module-detail',
    'wordscape',
    'altspace-vr',
    'altspace-vrws',
    'lesson',
    'skills',
    'skill-detail',
    'skill-detail-assess',
    'skill-detail-sims',
    'skill-detail-quiz',
    'quiz-result',
    'rewards',
    'chatbot',
    'video',
    'sticky',
    'sticky-demo',
    'planner'
]
let tutData = [
    {
        link: 'https://stickball.s3.amazonaws.com/Take+a+Tour.mp4'
    },
    {
        link: 'https://stickball.s3.amazonaws.com/Podcast+Creation.mp4'
    }
]
export default function Tutorial({orgID}) {
    
    let tutData = [
        {
            link: 'https://stickball.s3.amazonaws.com/Take+a+Tour.mp4'
        },
        {
            link: 'https://stickball.s3.amazonaws.com/Podcast+Creation.mp4'
        }
    ]
    if(orgID == "65fdbd97bdd44a00129afad7"){
        tutData=[
            {
                link:'https://stickball.s3.amazonaws.com/Stickball-Overview.mp4'
            }
        ]
    }
    const location = useLocation();
    const { pathname } = location;
    const curentPath = pathname.split('/').pop();
    const [currentPage] = useState(possiblePages?.filter(page => page.link === curentPath)[0]?.page || 'null')
    const [showTutButton, setShowTutButton] = useState(true)
    const [currentPageData, setCurrentPageData] = useState({})
    const [open, setOpen] = useState(false)
    const [numPages, setNumPages] = useState(null);

    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.js',
    //     import.meta.url,
    // ).toString();

    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    // }
    // const { isLoading, data: tutorialData } = useQuery('get-tutorials', getTutorials)

    // useEffect(() => {
    //     if (!isLoading) {
    //         tutorialData?.payload?.map(tut => {
    //             if (tut?.pageName === currentPage) {
    //                 setCurrentPageData(tut)
    //                 setShowTutButton(true)
    //             }
    //         })
    //     }
    // }, [tutorialData, isLoading])

    // useEffect(() => {
    //     const hasVisitedBefore = localStorage.getItem(`hasVisitedMyPage${curentPath}`);
    //     if (!hasVisitedBefore) {
    //         setOpen(true)
    //         localStorage.setItem(`hasVisitedMyPage${curentPath}`, 'true');
    //     }
    // }, [])
    if (!showTutButton) return (<></>)
    return (
        <>

            <div variant="outlined" style={
                tutBtnStyle
            }
                onClick={() => setOpen(true)}
            ><QuestionMarkIcon style={{ width: '15px', height: '15px' }} /></div>
            <Dialog
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        {/* Tutorial */}
                    </span>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CarouselProvider
                        naturalSlideWidth={"100%"}
                        naturalSlideHeight={"400px"}
                        totalSlides={tutData?.length + 1}
                        visibleSlides={1.1}
                    >
                        <Slider>
                            {
                                tutData?.map((item, index) => {
                                    return (
                                        <Slide style={{
                                            marginLeft: '10px', borderRadius: '15px',
                                            height: "400px"
                                        }} index={index} key={index} >
                                            <div className="slid-item">
                                                <video
                                                    controls
                                                    autoPlay
                                                    width='100%'
                                                    height='400px'
                                                    playsInline
                                                    alt="All the devices"
                                                    src={item?.link}
                                                >

                                                </video>
                                            </div>
                                        </Slide>
                                    )
                                })
                            }
                        </Slider>
                        {/* <div className="slid-item">
                            <video
                                controls
                                autoPlay
                                width='100%'
                                height='400px'
                                playsInline
                                alt="All the devices"
                                src={"https://stickball.s3.amazonaws.com/Take+a+Tour.mp4"}
                            >

                            </video>
                        </div> */}
                        {/* <DotGroup disableActiveDots={true} /> */}
                       {tutData.length > 1 && <div style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                            <ButtonBack style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <IconButton>
                                    <ArrowBackIosRoundedIcon />
                                </IconButton>
                            </ButtonBack>
                            <ButtonNext style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <IconButton>
                                    <ArrowForwardIosRoundedIcon />
                                </IconButton>
                            </ButtonNext>
                        </div>}

                    </CarouselProvider>


                    {
                        // currentPageData?.fileType === "PDF" ?
                        //     <div style={{ width: '100%', height: '600px', display: 'flex', justifyContent: 'center' }}>
                        //         <Document file={process.env.NEXT_PUBLIC_S3_BUCKET_LINK + currentPageData?.fileLink} onLoadSuccess={onDocumentLoadSuccess}>
                        //             {Array.from(
                        //                 new Array(numPages),
                        //                 (el, index) => (
                        //                     <Page
                        //                         key={`page_${index + 1}`}
                        //                         pageNumber={index + 1}
                        //                     />
                        //                 ),
                        //             )}
                        //         </Document>
                        //     </div>
                        //     : currentPageData?.fileType === "Video" ?
                        //         <video width='100%' height="auto" controls autoPlay>
                        //             <source src={process.env.NEXT_PUBLIC_S3_BUCKET_LINK + currentPageData?.fileLink} type="video/mp4" />
                        //         </video>
                        //         :
                        // <img width="80%" src={process.env.NEXT_PUBLIC_S3_BUCKET_LINK + currentPageData?.fileLink || "https://i.pinimg.com/originals/86/8c/ca/868ccae96fe7219f7a0f5305c529c58f.gif"} alt="gif" />
                    }

                </DialogContent>
            </Dialog>
        </>
    )
}
const tutBtnStyle = {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
    borderRadius: '50%',
    position: 'fixed',
    zIndex: 9999,
    left: 39,
    bottom: 10,
    backgroundColor: '#fff',
    cursor: 'pointer'

}