import React from 'react'
import Slider from "react-slick";
import { useEffect } from 'react'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setWords, setSkillName } from '../../redux-features/user/userSlice'
import { Grid, Tooltip, Typography } from "@mui/material"
import Button from '@mui/material/Button';
import CelebrationIcon from '@mui/icons-material/Celebration';

import { Link } from 'react-router-dom';

const Entrepreneur = (props) => {
    const { mutate: log } = useMutation(userLog);
    const user = useSelector((state) => state?.user?.userData?.user);
    const { lesson, keyIndex, moduleId, moduleContent, currentSkill, currentLesson, nextSkill } = props;
    const dispatch = useDispatch();


    const settings = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 0,
        infinite: false,
        arrows: false
    };



    return (
        <>
            {
                !!props.module?.lessons.length ?
                    <>
                        <div className='slider_all'>
                            <Slider {...settings} className="slider_setting">
                                {
                                    props.module?.lessons?.map((lesson) => {
                                        let matched = user?.badges?.length && lesson?.badges.map(val => user?.badges?.find(el => el.id == val.id))
                                        return (
                                            <div className="customItem">
                                                <div className="Literacy-card" id='style-3'>
                                                    <div className="box-financial">
                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                            <h3>{lesson.name}</h3>
                                                            <div style={{ display: "flex", gap: "10px" }}>
                                                                {
                                                                    (!!lesson.isCompleted && !!matched.length) &&
                                                                    matched?.slice(0, 2)?.map((badge) => {
                                                                        return (
                                                                            <Tooltip title={badge?.name} placement='top' arrow >
                                                                                <div
                                                                                    className="icon p-2"
                                                                                    style={{
                                                                                        backgroundColor: "#616ded",
                                                                                        width: "35px",
                                                                                        height: "35px",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        borderRadius: "8px",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        width="30px"
                                                                                        src={badge?.iconID?.svg}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </Tooltip>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                        {/* <Grid container gap={"1rem"}> */}
                                                        {/* <Grid item sx={{ background: "white", padding: "0.5rem 1rem", borderRadius: "0.5rem" }}>
                                                            <Typography className="innerhead" sx={{ color: "grey" }}>Grade</Typography>
                                                            <Typography className="innerhead" sx={{ color: "black", fontWeight: "700" }}> 9 - 12</Typography>
                                                        </Grid> */}
                                                        <Grid item sx={{ background: lesson.isCompleted ? "#33C052" : "white", padding: "0.5rem 1rem", borderRadius: "0.5rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography sx={{ color: lesson.isCompleted ? "white" : "grey" }}>Completed</Typography>
                                                            <Typography sx={{ color: lesson.isCompleted ? "white" : "black", fontWeight: "700" }}>{lesson.skills.filter(skill => { return (skill.isCompleted) })?.length}/{lesson?.skills?.length}</Typography>
                                                        </Grid>
                                                        {/* </Grid> */}
                                                        <ul>
                                                            {/* <li>
                                                <h6>Grade 9 - 12</h6>
                                            </li> */}
                                                            <li>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="card-body" >
                                                        <div className="scroll_inner" id='style-3'>
                                                            {
                                                                lesson?.skills?.map((skill) => (
                                                                    <>
                                                                        <Link className="skill-next" to={skill.isEnrolled ? "/skills?moduleId=" + moduleId + "&lessonId=" + lesson.id + "&skillId=" + skill.id : ''}
                                                                            onClick={(e) => {
                                                                                log({
                                                                                    pageName: 'Lesson',
                                                                                    buttonName: skill?.name
                                                                                })
                                                                                dispatch(setSkillName(skill?.name))
                                                                                lesson.isCompleted && dispatch(setWords(lesson?.words))
                                                                                !skill?.isEnrolled ?
                                                                                    e.preventDefault()
                                                                                    :
                                                                                    <></>
                                                                            }}
                                                                            style={{ color: skill.isCompleted ? "#33C052" : "#616DED" }}
                                                                        >
                                                                            <div className="box-title" style={{ backgroundColor: !skill.isEnrolled ? "#F2F2F2" : skill.isCompleted ? "#E5FEEB" : "rgba(76,110,215,0.5)" }}>

                                                                                {skill?.name}

                                                                            </div>
                                                                        </Link>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                            </Slider>

                        </div>
                    </>
                    :
                    <>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <img width="30%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                        </div>
                    </>
            }

        </>
    )
}

export default Entrepreneur






