import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSkillSections, setIDs, setChatbot, setSubSkillName } from '../../../redux-features/user/userSlice'

import { Grid, Typography, Button } from '@mui/material'

import { QuillEditor } from './quil-editor'


import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';

export default function SkillOne(props) {


    const { mutate: log } = useMutation(userLog);
    const [boxHeight, setBoxHeight] = useState(200)
    const dispatch = useDispatch()
    const { skill, keyIndex, moduleId, lessonId, skillId } = props;
    const navigate = useNavigate()


    const handleEnrollment = (skill) => {
        dispatch(setSubSkillName(skill?.name))
        const header = {
            module: moduleId,
            lesson: lessonId,
            skill: skillId,
            subSkill: skill?.id
        }
        dispatch(setChatbot(skill?.chatBot))
        if (skill?.isEnrolled) {
            dispatch(setSkillSections({
                skillSections: skill?.skillSections,
                quiz: skill?.quiz || []
            }));
            dispatch(setIDs(header))
            if (skill?.skillSections?.filter(section => { return (section.name === "Intro" && section.isCompleted) })?.length !== skill?.skillSections.filter(section => { return (section.name === 'Intro') })?.length) {
                navigate('/skill-detail')
            } else if (skill?.skillSections?.filter(section => { return (section.name === "Assess" && section.isCompleted) })?.length !== skill?.skillSections.filter(section => { return (section.name === 'Assess') })?.length) {
                navigate('/skill-detail-assess')
            } else if (skill?.skillSections?.filter(section => { return (section.name === "SIMS" && section.isCompleted) })?.length !== skill?.skillSections.filter(section => { return (section.name === 'SIMS') })?.length) {
                navigate('/skill-detail-sims')
            } else {
                navigate('/skill-detail')
            }
        }
    }

    function handleOrientation() {
        if (window.innerWidth > 1200) {
            setBoxHeight(300)
        } else if (window.innerWidth > 1150) {
            setBoxHeight(250)
        } else if (window.innerWidth > 1100) {
            setBoxHeight(250)
        } else if (window.innerWidth > 1000) {
            setBoxHeight(250)
        } else if (window.innerWidth < 1000) {
            setBoxHeight(120)
        }
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        if (window.innerWidth > 1200) {
            setBoxHeight(300)
        } else if (window.innerWidth > 1150) {
            setBoxHeight(280)
        } else if (window.innerWidth > 1100) {
            setBoxHeight(250)
        } else if (window.innerWidth > 1000) {
            setBoxHeight(200)
        } else if (window.innerWidth < 1000) {
            setBoxHeight(120)
        }

    }, [window.innerWidth, window.innerHeight, window.orientation]);

    return (
        <>
            <Grid container justifyContent="space-between" item className="subskillredesign__singleskill" style={{ maxWidth: "400px", minWidth: "400px" }}>

                <Grid>
                    <Grid item container xs={12} justifyContent="flex-start" alignItems="center" className="subskillredesign__singleskillinner" p={2} sx={{ borderRadius: "1rem", flexWrap: "nowrap" }}>
                        <Grid item xs={12}>
                            <Typography className="skilloneheading" sx={{ fontWeight: "700" }}>{skill?.name}</Typography>
                        </Grid>
                        {/* <Grid item xs={5} container justifyContent="flex-end">
                            <img style={{maxWidth: '50px', color:"#616DED"}} src={skill?.icon?.svg} />
                        </Grid> */}
                    </Grid>
                    <QuillEditor value={skill?.description} readOnly={true} className="quillllls" />
                </Grid>

                <Grid>
                    <Grid item justfiyContent="center" container mt={2}>
                        <Button onClick={() => handleEnrollment(skill)} className="skillonebtn" sx={{ color: skill?.isCompleted ? "rgb(51, 192, 82)" : "#8D95F2", background: skill.isCompleted ? "rgb(229, 254, 235)" : "#FAFBFF", width: "100%" }}>
                            {skill?.isCompleted ? 'Completed' : 'Get Started'}
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
            {/* <div key={keyIndex} className="skills-content col-4" style={{ minHeight: boxHeight }}>
                <div className="slid-item" style={{ width: '100%', height: '100%' }}>
                    <div className="left-section">
                        <img src={skill?.icon?.svg} alt="" />
                    </div>
                    <div className="right-section d-flex flex-column justify-content-between" style={{ width: '100%' }}>
                        <div style={{ height: boxHeight, overflow: 'auto' }}>
                            <h3>{skill?.name}</h3>
                            <QuillEditor value={skill?.description} readOnly={true} />
                        </div>
                        <button
                            style={{
                                backgroundColor: skill.isEnrolled ? "#4c6ed7" : "rgba(76,110,215,0.5)",
                                fontSize: skill?.isCompleted && '16px', fontWeight: skill.isCompleted && '600'
                            }}
                            onClick={() => {
                                log({
                                    pageName: 'Skills',
                                    buttonName: skill?.name
                                })
                                handleEnrollment()
                            }}>{skill.isCompleted ? 'Completed' : 'Start'}</button>
                    </div>
                </div>
            </div> */}

        </>
    )
}
