import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TurnPhoneV from '../other/TurnPhoneV'
import isAuthenticated from '../auth/auth'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux'

export default function ChatBot() {
    const { mutate: log } = useMutation(userLog);
    const chatbot = useSelector(state => state.user?.chatbot)
    function handleClick(e) {
        e.preventDefault()
        window.history.go(-1)

        log({
            pageName: "ChatBot",
            buttonName: "Back"
        })
    }

    const [number, setNumber] = useState(1)
    const [answer, setAnswer] = useState([])
    const [chatContent, setChatContent] = useState([])
    useEffect(() => {
        if (chatContent.length === 0) {
            setChatContent(chatOld => [...chatOld, chatbot?.content])
        }
    }, [chatbot])
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneV />)
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <div className="back back2 get-started-code-back p-2 px-4" style={{ position: 'fixed', width: '100%', zIndex: 1000, top: '0px', left: '0px', boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.1)' }}>
                    <div className="back-wrapper">
                        <a
                            // to={props?.backLink}
                            onClick={(e) => handleClick(e)}>
                            <img src="/assets/svg/back-arrow.svg" alt="" />

                        </a>
                    </div>
                </div>
                <div className="chatbot-container mt-3">
                    <div className="inner-chatbot">

                        {
                            chatbot?.description === "Stickbot" ?
                                <>
                                    {
                                        chatContent?.map((que, i) => {
                                            return (<>
                                                <div className="row">
                                                    <Avatar src='assets/svg/chatbot.svg' alt="Chatbot" style={{ background: '#FB785C', height: 'fit-content', width: 'fit-content', padding: '5px' }} />
                                                    <span>StickBot</span>
                                                </div>
                                                {
                                                    i === 0 &&
                                                    <div className="question hi">
                                                        👋 Hi​! I'm a StickBot. Let me know if you have any questions!
                                                    </div>
                                                }

                                                <div className="question">
                                                    {que.content}
                                                </div>
                                                <div className="options1 mt-2">
                                                    {
                                                        que.children?.map((option, index) => {
                                                            return (<Chip key={index} label={option.content} sx={{ p: 0 }} style={{ width: 'fit-content', padding: '2px 16px', color: answer[i] === option.content ? '#fff' : '#FB785C', border: '1px solid #FB785C', background: answer[i] === option.content ? '#FB785C' : 'transparent' }}
                                                                onClick={() => {
                                                                    if (i === chatContent.length - 1) {
                                                                        setChatContent([...chatContent, option?.children[0]])
                                                                    }

                                                                    if (i === answer.length) {
                                                                        setAnswer([...answer, option.content])
                                                                    }
                                                                }}
                                                            />)
                                                        })
                                                    }

                                                </div>
                                                {
                                                    answer[i] &&
                                                    <>
                                                        <div className="row d-flex justify-content-end">
                                                            <div style={{ width: 'fit-content' }}>
                                                                <span>You</span>
                                                                <img style={{ width: '24px', marginLeft: '20px' }} src='assets/svg/chat-user.svg' alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-end">
                                                            <div className="answer">
                                                                {answer[i]}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </>)
                                        })
                                    }
                                </>
                                : <>
                                    <iframe width="100%" style={{ height: '100%' }} src={chatbot.description?.slice(8)} frameborder="0"></iframe>
                                </>
                        }

                    </div>
                </div>
            </>
        )
}
