import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Grid, Button, Typography, Box, Container, useMediaQuery, Link as ALink, Stack, Tooltip, Avatar } from "@mui/material"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Loading from '../common/Loading'
import TurnPhoneH from '../other/TurnPhoneH'


// import { ChatBotBody } from "../common/chatbot"
import { useTheme } from '@mui/material/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { emptyStore, setRegister } from '../../redux-features/user/userSlice'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from 'react-query'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import uuid from 'react-uuid'

import Notifications from '../notifications'
import Swal from 'sweetalert2'
import { userLog } from '../_api'
import isAuthenticated from '../auth/auth'

const Modules = () => {
    const NOUNCE = uuid();
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const userData = useSelector((state) => state?.user?.userData?.data)
    const user = useSelector((state) => state?.user?.userData?.user)
    const loading = useSelector(state => state?.user?.loading)
    const [nextSkillID, setNextSkillID] = useState()
    const [nextSkillName, setNextSkillName] = useState()

    window.name = "Success"
    useEffect(() => {
        localStorage.setItem("fetch-data", false)
        dispatch(setRegister(false))
        userData?.map((module) => {
            module?.lessons?.map((lesson) => {
                lesson?.skills?.map((skill) => {
                    if (skill?.isCompleted == false) {
                        setNextSkillID(skill?.id)
                        setNextSkillName(skill?.name)
                    }
                })
            })
        })

    }, [userData])
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [message, setMessage] = useState("")
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);


    useEffect(() => {
        localStorage.setItem("NOUNCE", NOUNCE);
    }, [NOUNCE]);

    useEffect(() => {
        if (!isAuthenticated(localStorage.getItem("token"))) {
            navigate("/login")
        }
    }, [])

    if (!userData?.length) {
        return (<>
            {loading && <Loading />}
            {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
        </>)
    }
    else if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to view the App" />)
    }
    else {
    return (
        <>
            <Box sx={{ mb: 2, backgroundColor: (theme) => theme.palette.primary.main }}>
                <Grid style={{ backgroundColor: (theme) => theme.palette.primary.main }} container item sm={12} p={2} justifyContent="space-around">
                    <Grid sm={2} justifyContent="center" paddingBottom="10px">
                        <img width="150px" height="50px" src="/assets/svg/logo-white.gif" alt="" />
                    </Grid>
                    <Grid container sm={6} justifyContent="flex-end" width="auto">
                        <Stack direction="row" alignItems="center" gap={2}>
                            <Link to={"/profile"}
                                onClick={(e) => {
                                    if (sessionStorage.getItem("access") === "ok") {
                                        e.preventDefault();
                                        Swal.fire({
                                            title: '',
                                            text: "It's a teacher view, so there is no profile to show!",
                                            icon: 'info',
                                            confirmButtonText: 'OK'
                                        })
                                    }
                                }}
                            >
                                <Tooltip title="Profile" arrow>
                                    <Avatar sx={{ width: 50, height: 50 }}>
                                        {user?.photo && <img src={user?.photo} style={{ width: "100%" }} />}
                                    </Avatar>
                                </Tooltip>
                            </Link>
                            <Link to="/planner"
                                onClick={(e) => {
                                    if (sessionStorage.getItem("access") === "ok") {
                                        e.preventDefault();
                                        Swal.fire({
                                            title: '',
                                            text: "It's a teacher view, so there is no planner to show!",
                                            icon: 'info',
                                            confirmButtonText: 'OK'
                                        })
                                    }
                                }}
                            >
                                <Button
                                    id="basic-button"
                                >
                                    <div className="logged hover-logged">
                                        <CalendarMonthIcon style={{ width: '40px', height: '40px', marginTop: -5, color: 'inherit' }} />
                                    </div>
                                </Button>
                            </Link>
                            <div className="logoutIcon" onClick={() => {
                                if (sessionStorage.getItem("access") === "ok") {
                                    Swal.fire({
                                        title: '',
                                        text: "It's a teacher view, so you can't logout!",
                                        icon: 'info',
                                        confirmButtonText: 'OK'
                                    })
                                    return
                                }
                                dispatch(emptyStore())
                                localStorage.removeItem("token")
                                window.location.reload()
                                log({
                                    pageName: 'Module',
                                    buttonName: 'Logout Button'
                                })
                                navigate("/get-started")
                            }}>
                                <div className="logged" style={{ background: "white" }}>
                                    <div class="block">
                                        <span className="animatedIcony">
                                            <div class="div1" >
                                                <img src="/assets/img/logout1.png" />
                                            </div>
                                            <div class="rect">
                                                <img src="/assets/img/logout.png" />
                                            </div>
                                            <div class="div2">
                                                <img src="/assets/img/logout1.png" />
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Stack>
                        <Notifications />
                    </Grid>
                </Grid>
                <Box sx={heroStyle}
                >
                    <img
                        style={{
                            height: "100%",
                            position: "absolute",
                            objectFit: 'cover',
                            right: "12%"
                        }}
                        src='./assets/img/personBanner.png' />
                    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', position: 'relative', zIndex: 1 }}>
                        <Typography variant="h3" sx={heroTextStyle}>Financial Education Center</Typography>
                        {/* <ALink
                            href="https://www.brooklinebank.com/"
                            sx={btnBackToBBStyle}
                        >
                            <ArrowBackIosIcon sx={{ width: { sm: "24px", xs: "12px" }, height: { sm: "24px", xs: "12px" } }} />
                            {t("global.backToBrooklineBank")}
                        </ALink> */}
                    </Container>
                </Box>
            </Box >
            <Container maxWidth="lg" >
                <Grid container sx={{ my: 5, gap: 5 }} justifyContent="center">
                    {userData !== undefined &&
                        userData?.map((item, index) => (
                            <Grid item md={userData?.length > 3 ? 4 : 3.5} sm={5.5} xs={12}
                                sx={boxStyle}>
                                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: item.name === "Money" ? "center" : "flex-start" }}>
                                    {
                                        item.name === "Money" ?
                                            <img style={{ width: "250px" }} src="../assets/img/money-logo-spanish.png" alt="" />
                                            :
                                            item?.icon?.svg &&
                                            <Box className="modulesredesign__moduleName">
                                                <img style={{ width: item?.icon.height ? "fit-content" : "50px", height: item.icon.height ? item.icon.height : "fit-content" }} src={item?.icon?.svg} />
                                            </Box>
                                    }
                                    {
                                        item.name !== "Money" &&
                                        <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "20px", md: "25px" }, gap: "1rem" }} vriant="h4">
                                            {item?.name}
                                        </Typography>
                                    }
                                </Box>
                                <Box>
                                    {
                                        item.name === "Money" ?
                                            <a
                                                href={process.env.REACT_APP_BUDG_LINK + "?lang=" + localStorage.getItem("lang")}
                                                style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer" }}
                                            >
                                                <Button
                                                    sx={{ background: "#FB785C", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: "#FB785C" } }}>
                                                    <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                                                </Button>

                                            </a>
                                            :
                                            <Link to={item?.isEnrolled ? `/lesson?id=${item?.id}` : "/module"} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: item?.isEnrolled ? "pointer" : "text" }}>
                                                <Button disabled={!item?.lessons?.length} sx={{ background: "#FB785C", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: "#FB785C" } }}>
                                                    <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                                                </Button>
                                            </Link>
                                    }
                                </Box>
                            </Grid>
                        ))
                    }

                    <Grid item md={userData?.length > 3 ? 4 : 3.5} sm={5.5} xs={12}
                        sx={boxStyle}>
                        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: "center" }}>
                            <img style={{ width: "250px" }} src={"../assets/img/money-logo.png"} alt="" />
                        </Box>
                        <Box>

                            <a
                                href={process.env.REACT_APP_BUDG_LINK + "?lang=" + localStorage.getItem("lang")}
                                style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer" }}
                            >
                                <Button
                                    sx={{ background: "#FB785C", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: "#FB785C" } }}>
                                    <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                                </Button>

                            </a>

                        </Box>
                    </Grid>
                </Grid>
                {/* <ChatBotBody setChatBotModal={setChatbotModal} data={userData?.map(module => module?.name)} pageName={"Module"} chatBotModal={openChatbotModal} /> */}
            </Container>
        </>
    )
}

}

export default Modules

const boxStyle = {
    p: 3,
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:"#616DED",
    justifyContent: 'space-between',
    gap: 2
}

const heroStyle = {
    position: "relative",
    background: "#eeedf2", // 'linear-gradient(to bottom, #f0f0f0 0%, #bcbcbc 90%)',
    height: { sm: '300px', xs: "100px" },
    width: '100%',
}

const btnStyle = {
    backgroundColor: "#829d00",
    minHeight: { sm: '48px', xs: "fit-content" },
    padding: '0 clamp(0.625rem, 1.25vw, 1.25rem)',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: { sm: 'bold', xs: 500 },
    color: '#fff',
    fontSize: '1rem',
    border: 0,
    width: 'fit-content',
    '&:hover': {
        backgroundColor: "#106EAC",
        color: 'white'
    }
}

const btnBackToBBStyle = {
    backgroundColor: "transparent",
    minHeight: { sm: '48px', xs: "fit-content" },
    padding: { sm: '10px', xs: "5px" },
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: { sm: 700, xs: 500 },
    fontSize: { sm: '1rem', xs: "10px" },
    width: 'fit-content',
    borderRadius: { sm: "10px", xs: '5px' },
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    border: `1px solid`,
    '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.light,
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
    }
}

const heroTextStyle = {
    maxWidth: { sm: "50%", xs: "70%" },
    color: 'dark',
    fontWeight: 700,
    mb: { sm: 3, xs: 1.5 },
    fontSize: { md: "3rem", sm: "2.3rem", xs: "18px" }
}