import axios from "axios";
import { create } from 'apisauce';

const api = create({
    headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY
    },
});
const handlePromise = (resolve, reject, response) => {
    if (
        (response?.data?.success && response.status === 200) ||
        response.status === 201 ||
        response.originalError === null
    ) {
        resolve(response.data);
    } else {
        reject(response?.data);
    }
};
const API_URL = process.env.REACT_APP_API_LINK
export const sendMessageToGpt = async (newMessages, data, pageName, gradeNumber,) => { // Pass newMessages as a parameter
    const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
            model: 'gpt-3.5-turbo',
            messages: [
                { "role": "system", content: constructPrompt(pageName, data, gradeNumber) },
                ...newMessages.map(msg => ({ role: msg.sender, content: msg.message }))
            ]
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
            }
        }
    );
    return response.data.choices[0].message.content; // Return the bot reply content
}

export const sendMessageToModuleGpt = async (payload, id) => {
    const isHandle = true
    const Header = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };

    const response = await api.post(`${API_URL}/module/${id}/chat`, payload, Header);
    if (isHandle) {
        return new Promise((resolve, reject) => {
            handlePromise(resolve, reject, response);
        });
    } else {
        return response;
    }
}

const constructPrompt = (pageName, data, gradeNumber) => {
    if (pageName === "Lesson") return `You are a helpful assistant. Your role is to provide information about the lessons and skills visible on this screen. You can access and refer to a list of lessons with their skills such as ${JSON.stringify(data)} to answer user queries accurately. For answers you must have to follow the reading level of grade ${gradeNumber} of a student.`
    if (pageName === "SubSkills") return `You are a helpful assistant. Your role is to provide information about the sub skills visible on this screen. You can access and refer to a list of sub skills with their respective  descriptions such as ${JSON.stringify(data)} to answer user queries accurately. For answers you must have to follow the reading level of grade ${gradeNumber} of a student.`
    if (pageName === "Quiz") return `You are a helpful assistant. Your role is to provide information about the sub quiz visible on this screen. You can access and refer to a list of quzi with their respective questions adn options such as ${JSON.stringify(data)} to answer user queries accurately. For answers you must have to follow the reading level of grade ${gradeNumber} of a student.`
    return `You are a helpful assistant. Your role is to provide information about the skill section visible on this screen. You can access and refer to the skill section such as ${JSON.stringify(data)} to answer  user queries accurately. For answers you must have to follow the reading level of grade ${gradeNumber} of a student.`
}