
import React, { useEffect, useState } from 'react'
import Loading from '../common/Loading'
import SkillSection from '../lessons/details/SkillSection'
import { getSections } from '../_api'
import Splash2 from '../splash-screen/Splash2'
import Quiz from './Quiz'

function PreviewCheck() {
    const [loader, setLoader] = useState(true)
    const [content, setContent] = useState(null)
    const [quiz, setQuiz] = useState([])
    const [nextSrc, setNextSrc] = useState("../../assets/img/nextt.png")
    const [backSrc, setBackSrc] = useState("../../assets/img/backk.png")
    const [previewNav, setPreviewav] = useState({
        content: true,
        quiz: false
    })


    const [skillSections, setSkillSections] = useState([])
    const [index, setIndex] = useState(0)


    const subSkillId = sessionStorage.getItem("subSkillId")
    const skillSectionID = sessionStorage.getItem("skillSectionID")
    const view = sessionStorage.getItem("view")
    const skillSectionName = sessionStorage.getItem("skillSectionName")
    const contentType = sessionStorage.getItem("contentType")

    useEffect(() => {
        if (view === "subView" && subSkillId) {
            handleSubSkillPreview()
        } else if (subSkillId && skillSectionID) {
            handlePreview()
        }
    }, [])

    const handleSubSkillPreview = async () => {
        try {
            const res = await getSections(subSkillId)
            setSkillSections(res?.data?.data)
            setContent(res?.data?.data[0])
            setQuiz(res.data.quiz)
            setLoader(false)

        } catch (error) {
        }
    }

    const handlePreview = async () => {
        try {
            const res = await getSections(subSkillId)
            const skillSection = res?.data?.data.find(skillSection => skillSection.id == skillSectionID)

            setContent(skillSection)
            setQuiz(res.data.quiz)
            setLoader(false)

        } catch (error) {
        }
    }

    if (subSkillId && view === "subView") {
        if (loader && !content) return <Loading />
        const { content: isShowContent, quiz: isShowQuiz } = previewNav
        return <div className="skills-details-container">
            {<StaticSideBar content={content} quiz={false} handleNavigate={setPreviewav} activeTab={previewNav} />}
            <div className="row justify-content-center p-3 m-2" style={{ width: "100%", height: "100%" }} >
                {isShowContent && !!content && <SkillSection content={content} />}
                {isShowQuiz && !!quiz?.length && <Quiz quiz={quiz} />}
            </div>
            <div className="row">
                <button style={{ width: 'fit-content', border: 'none', padding: '0px', background: 'transparent' }} disabled={index === 0} className="prev" onClick={() => {
                    setIndex(index - 1)
                    setContent(skillSections[index - 1])
                }} >
                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                </button>
                <button style={{ width: 'fit-content' }} disabled={index + 1 === skillSections?.length} className='next' onClick={() => {
                    setIndex(index + 1)
                    setContent(skillSections[index + 1])
                }} >
                    <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                </button>
            </div>
        </div>
    }

    if (contentType) {
        let tempContent = {}
        if (
            contentType === "Matterport-Stickbot" || contentType === "Iframe-Iframe" || contentType === "Iframe-TikTok"
            || contentType === "Iframe-Audio" || contentType === "Iframe-Video" || contentType === "Content-Image-Wireframe"
            || contentType === "Content-Image-Stickbot" || contentType === "Image-Video" || contentType === "Image-Audio" ||
            contentType === 'Wireframe-Stickbot' || contentType === 'Stickbot-Wireframe' || contentType === 'Youtube-Stickbot'
        ) {
            let links = {
                wireframe_link: "",
                wireframe_link1: ""
            }
            tempContent = {
                name: skillSectionName,
                allContent: {
                    content: JSON.stringify(links),
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        } else if (contentType === 'Video-Stickbot-Audio') {
            let links = {
                video_link: "",
                audio_link: "",
                heading1: "",
                heading2: "",
                stickbot_link: "",
            }
            tempContent = {
                name: skillSectionName,
                allContent: {
                    content: JSON.stringify(links),
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        } else if (contentType === 'Video-Audio') {
            let links = {
                wireframe_link: "",
                wireframe_link1: "",
                text: '',
                vidText: ''
            }
            tempContent = {
                name: skillSectionName,
                allContent: {
                    content: JSON.stringify(links),
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        } else if (contentType === 'Image-Stickbot-Audio') {
            let links = {
                video_link: [],
                audio_link: "",
                heading1: "",
                heading2: "",
                stickbot_link: "",
            }
            tempContent = {
                name: skillSectionName,
                allContent: {
                    content: JSON.stringify(links),
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        } else if (contentType === 'Dropdown' || contentType === "CareerHistory") {

            tempContent = {
                name: skillSectionName,
                allContent: {
                    content: JSON.stringify([]),
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        } else if (contentType === 'Video-Six-Grid' || contentType === 'Image-Grid') {
            tempContent = {
                name: skillSectionName,
                allContent: {
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        } else {

            tempContent = {
                name: skillSectionName,
                allContent: {
                    content: "",
                    name: "",
                    description: "",
                    type: contentType,

                },
            }
        }
        return <div className="skills-details-container">
            <StaticSideBar content={tempContent} quiz={[]} handleNavigate={setPreviewav} activeTab={previewNav} />
            <div className="row justify-content-center mt-3" style={{ width: "100%", height: "100%" }} >
                <SkillSection content={tempContent} />
            </div>
        </div>
    }

    if (subSkillId && skillSectionID) {
        if (loader && !content) return <Loading />
        const { content: isShowContent, quiz: isShowQuiz } = previewNav
        return <div className="skills-details-container">
            {<StaticSideBar content={content} quiz={quiz} handleNavigate={setPreviewav} activeTab={previewNav} />}
            <div className="row justify-content-center p-3 m-2" style={{ width: "100%", height: "100%" }} >
                {isShowContent && !!content && <SkillSection content={content} />}
                {isShowQuiz && !!quiz?.length && <Quiz quiz={quiz} />}
            </div>
        </div>
    }

    return <Splash2 />
}

export default PreviewCheck;

const StaticSideBar = ({ content, quiz, handleNavigate, activeTab }) => {
    const { name } = content
    const { content: isActiveContent, quiz: isActiveQuiz } = activeTab

    return (
        <div className="skills-sidebar-container">
            <div className="sidebar">

                {name === "Intro" && <a onClick={() => handleNavigate({ content: true, quiz: false })} className={isActiveContent ? "bar-item active mt-0 " : "bar-item "} >
                    {
                        isActiveContent ?
                            <img src="../assets/img/intro1.svg" alt="" />
                            :
                            <img src="../assets/img/intro.svg" alt="" />
                    }
                    <p>Intro</p>
                </a>}

                {name === "Assess" && <a onClick={() => handleNavigate({ content: true, quiz: false })} className={isActiveContent ? "bar-item active mt-0 " : "bar-item "} >
                    {
                        isActiveContent ?
                            <img src="../assets/img/assets1.svg" alt="" />
                            :
                            <img src="../assets/img/assets.svg" alt="" />
                    }
                    <p>Assess</p>
                </a>}

                {name === "SIMS" && <a onClick={() => handleNavigate({ content: true, quiz: false })} className={isActiveContent ? "bar-item active mt-0 " : "bar-item "}>
                    {
                        isActiveContent ?
                            <img src="../assets/img/sim1.svg" alt="" />
                            :
                            <img src="../assets/img/sim.svg" alt="" />
                    }
                    <p>Sims</p>
                </a>}
                {!!quiz?.length && <a onClick={() => handleNavigate({ content: false, quiz: true })} className={isActiveQuiz ? "bar-item active mt-0 " : "bar-item "}>
                    {
                        isActiveQuiz ?
                            <img src="../assets/img/quiz1.svg" alt="" />
                            :
                            <img src="../assets/img/quiz.svg" alt="" />
                    }
                    <p>Quiz</p>
                </a>}

            </div>
        </div>
    )
}
