import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from '../common/NewHeader'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { login, userLog, resetPassword, verifyOTP} from '../_api/index'
import isAuthenticated from '../auth/auth'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import uuid from 'react-uuid'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, Typography, FormControl, TextField } from "@mui/material"
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close'

// import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';

export default function GotCode() {
    const { mutate: log } = useMutation(userLog);
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: verify } = useMutation(verifyOTP, {
        onSuccess: (response) => {
            // queryClient.invalidateQueries('request-code');
            setSuccessDialog(true)
            setTimeout(() => {
                navigate(`/reset-password`,{state:{code:code,email:email}})
            }, 2000);
        },
        onError: (error) => {
                setMessage("OTP did not match , please try again!")
        }
    });
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    
    const location = useLocation()
    const email = location.state?.email

    const [successDialog, setSuccessDialog] = useState(false)
    const [emailSentAlert, setEmailSentAlert] = useState(true)

    const [serverError, setServerError] = useState(false)
    // const [email, setEmail] = useState("")
    const [token, setToken] = useState("")
    const [index, setIndex] = useState(0)
    const [error, setError] = useState(false)
    const [showPass1, setShowPass1] = useState(false)
    const [showPass2, setShowPass2] = useState(false)
    const [message, setMessage] = useState("")
    const [password, setPassword] = useState("")
    const NOUNCE = uuid();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { t } = useTranslation();
    const [code, setCode] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        if(!code){
            setMessage("enter code code is required")
        }else{
            verify({
                email:email,
                otp:code
            })
        }
    }
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);


    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to enter code or signup" />)
    } else
        return (
            <>
                <Header text={t("gotCode.skip")} backLink="/" textLink="/full-register" page="Got a Code" lang={true} />
                
                {
                    successDialog &&
                    <div className="full-screen-shadow">
                        <div className="success-dialog text-primary">
                            <DoneAllRoundedIcon style={{ fontSize: '50px' }} />
                            <h1>OTP verified</h1>
                        </div>
                    </div>
                }
                <Grid className="loginscreen" container sx={{ height: "100vh", padding: "8vw 14vw" }}>
                    <Grid item xs={12} md={7} conatainer justifyContent="center" sx={{flexDirection: "column"}}>

                    {!!emailSentAlert && 
                    <div className="mx-3 text-white" style={{top:47}}>
                            <Alert  className="alert" severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setEmailSentAlert(false)
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                            }
                            ><h5 className="text-success">{`OTP code sent to ${email} . `}
                                <b style={{cursor:"pointer"}}
                                   onClick={() => {
                                    navigate(`/reset-password`)
                                  }}>click here to change email</b></h5></Alert>
                     </div>}

                        <Grid sx={{width: {xs: "100%", md: "70%"}}}>
                        <h1 className="gotacode" style={{marginTop: "6rem"}}>Got a Code?</h1>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="enter-code-wrapper">
                                <div className="enter-code">
                                 <TextField
                                    variant="outlined"
                                    className="loginscreen__email"
                                    fullWidth
                                    id="email"
                                    size="small"
                                    type="number"
                                    onChange={(e) => {
                                        // if ((e.target.value).toString().length < 7) {
                                            setCode(e.target.value)
                                        // }
                                    }}
                                    placeholder={t('gotCode.placeholder')} required
                                    sx={{borderRadius: "10px", width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                                />
                                </div>
                            </div>
                            <p className="text-danger error-text">{message}</p>
                            <button
                                style={{ borderRadius: '10.4px', marginTop: "0px" }} type="submit" className="submit-btn"
                                onClick={() => log(
                                    {
                                        pageName: 'Got a Code',
                                        buttonName: 'Next'
                                    })}
                            >{t('gotCode.btnNext')} </button>
                        </form>
                        {/* <h1 className="got__code__head" style={{marginTop: "4rem"}}>Or</h1>
                        <Link to="/request-code" style={{ width: '100%' }}
                            onClick={() => log({
                                    pageName: 'Got a Code',
                                    buttonName: 'Request Code'
                                })}
                        >
                            <button
                                style={{ borderRadius: '10.4px', marginTop:"0rem" }} type="button" className="submit-btn-2">{t('gotCode.btnReq')}</button>
                        </Link> */}
                        </Grid>

                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center"  md={5}  sx={{display: {xs: "none", md: "flex"}}}>
                        <img className="loginscreen__illustration" src="../assets/img/gotacode.png" alt="" />
                    </Grid>
                </Grid>
            </>
        )
}
