import { useEffect, useState } from "react";

const Typewriter = ({ text, speed = 100 }) => {
    const [displayText, setDisplayText] = useState('');
  
    useEffect(() => {
      let i = 0;
  
      const type = () => {
        if (i < text?.length) {
          setDisplayText((prev) => prev + text.charAt(i));
          i++;
          setTimeout(type, speed);
        }
      };
  
      type();
    }, [text, speed]);
  
    return <div>{displayText}</div>;
  };
  export default Typewriter