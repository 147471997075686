import React, { useState } from 'react';
import {
    Grid,
    Typography,
    Box,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment'
import { EventsOfDayModal } from './modals'

export const WeeklyCalendar = ({ startOfWeek, endOfWeek, filteredDropedItems, dropedItems, eventStyle, skillCardStyle, openedCardDetails }) => {

    const [dayClicked, setDayClicked] = useState(null)
    const [openCurrentEvents, setOpenCurrentEvents] = useState(false)
    const events = filteredDropedItems(dropedItems, moment(dayClicked))
    const eachEvent = (day) => {
        return filteredDropedItems(dropedItems, moment(day))
    }

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const datesOfWeek = [];
    for (let day = startOfWeek; day <= endOfWeek; day = day.clone().add(1, 'd')) {
        datesOfWeek.push(day.toDate());
    }
    const timeSlots = [
        '', '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM',
        '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM',
        '8 PM', '9 PM', '10 PM', '11 PM'
    ];

    const Event = ({ event_color, start_date, end_date, id, event_name: name, position, datePosition }) => {
        if (moment(start_date).isSame(moment(datePosition), 'day')) {
            return (
                <Box sx={{ ...eventStyle, background: event_color, top: `${(position * 35) + 50}px`, width: '100%' }}
                // onClick={() => setSelectedEvent({ color, start_date, end_date, id, name, index, position })}
                >
                    <div style={{ width: '100%', fontSize: '12px' }}>
                        {name.slice(0, 12)}
                    </div>

                </Box>
            )
        }
        if (end_date && moment(start_date).isBefore(datePosition) && (moment(end_date)?.isSame(moment(datePosition), 'day') || moment(end_date)?.isAfter(datePosition))) {
            return (
                <Box sx={{ ...eventStyle, background: event_color, top: `${(position * 35) + 50}px`, width: '100%', fontSize: '12px' }}
                >
                    {name.slice(0, 12)}
                </Box>
            )
        }

        return null
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={1.5}></Grid>
            {daysOfWeek.map((day) => (
                <Grid item xs={1.5} key={day}
                    sx={dayBoxStyle}>
                    <Typography align="center" sx={dayStyle}>
                        {day}
                    </Typography>
                </Grid>
            ))}
            {
                timeSlots.map((ts, slIndex) => {
                    if (slIndex === 0) return (<>
                        <Grid item xs={1.5}></Grid>
                        {datesOfWeek.map((day, index) => (
                            <Grid item xs={1.5} key={index}
                                sx={{
                                    ...dateStyle,
                                    minHeight: eachEvent(day)?.length > 2 ?
                                        '180px' : eachEvent(day)?.length > 1 ?
                                            '120px' : eachEvent(day)?.length == 1 ?
                                                '80px' : 'fit-content'
                                }}
                                onClick={() => {
                                    setDayClicked(day)
                                    setOpenCurrentEvents(true)
                                }}
                            >
                                <Typography Typography variant='h5' sx={{
                                    ...activeDateStyle,
                                    background: moment(day).isSame(moment(), 'day') ? '#616ded' : 'transparent',
                                    color: moment(day).isSame(moment(), 'day') ? '#fff' : moment(day).isBefore(moment(), 'day') ? "rgba(55, 65, 81, 0.40)" : 'rgba(55, 65, 81, 0.80)',

                                }}>
                                    {moment(day).format('DD')}
                                </Typography >
                                {
                                    eachEvent(day)?.slice(0, 3)?.map((elem, pstn) => {
                                        return <div
                                        // onClick={(e) => { setSelectedEvent(elem); setOpenEvent(true) }} 
                                        >
                                            <Event {...elem} position={pstn} datePosition={day} />
                                        </div>
                                    })
                                }
                                {
                                    eachEvent(day)?.length > 3 && <MoreHorizIcon style={{ position: 'absolute', bottom: 0, right: 0 }} />
                                }
                            </Grid>
                        ))
                        }
                    </>)
                    return <>
                        <Grid item xs={1.5} sx={boxStyle}>{ts}</Grid>
                        {datesOfWeek.map((day, index) => (
                            <Grid item xs={1.5} key={index} sx={boxStyle} > </Grid>
                        ))}
                    </>
                })
            }
            {
                !!events?.length &&
                <EventsOfDayModal
                    openCurrentEvents={openCurrentEvents}
                    setOpenCurrentEvents={setOpenCurrentEvents}
                    events={events}
                    skillCardStyle={skillCardStyle}
                    openedCardDetails={openedCardDetails}
                />
            }
        </Grid >
    );
};


const boxStyle = {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    maxHeight: '200px',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    position: 'relative',
    overflow: 'hidden',
    color: '#111827',
    padding: '10px 2px',
}
const dayBoxStyle = {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}
const dayStyle = {
    color: '#111827',
    fontSize: '14px',
    fontWeight: 600,
}

const activeDateStyle = {
    width: '40px',
    height: '40px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%'
}
const dateStyle = {
    background: '#fff',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    color: '#111827',
    padding: '10px 2px',
}