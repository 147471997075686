import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import { Link, useNavigate } from 'react-router-dom'
import SideBar from '../details/SideBar'
import SkillOne from '../details/SkillOne'
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import { useSelector } from 'react-redux'
import isAuthenticated from '../../auth/auth'
import Loading from '../../common/Loading'
import { Grid, Button } from '@mui/material'
import { emptyStore } from '../../../redux-features/user/userSlice'
import { useDispatch } from 'react-redux'
import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import RewardsModal from '../details/RewardsModal'
import TranslateModal from '../details/TranslateModal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import Notes from '../notes/Notes'
import CloseIcon from '@mui/icons-material/Close';

import { useQuery } from "react-query";
import { getAllSticky } from "../../_api/sticky";
import Swal from 'sweetalert2';
import GoalsModal from '../../common/GoalsModal';
import TodoListModal from '../notes/TodoListModal.js'
import Notifications from '../../notifications/index.js'
import ChatBot from '../../common/chatbot.js'
export default function Subskills(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state?.user?.userData?.user)
    const userData = useSelector((state) => state?.user?.userData?.data)
    const { mutate: log } = useMutation(userLog);
    const modules = useSelector(state => state.user.userData?.data)

    const loading = useSelector(state => state.user?.loading)
    var url = new URL(window.location.href);
    var lessonId = url.searchParams.get("lessonId");
    var moduleId = url.searchParams.get("moduleId");
    var skillId = url.searchParams.get("skillId");
    const [nextLink, setNextLink] = useState(moduleId)
    const [open, setOpen] = useState(false)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [nextSrc, setNextSrc] = useState("../../assets/img/next.png");


    const [allSticky, setAllSticky] = useState('');
    const [currentLocation, setCurrentLocation] = useState({})

    const { isLoading, data: getSticky } = useQuery(["get-all-Stickies", 'assignment'], () => getAllSticky('assignment'));




    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            });
        } else {
            alert("Please try reloading and provide location permission to view your stickies")
        }
    }, [])

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(0);
    }

    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    useEffect(() => {
        let stickiesOnLocation = getSticky?.data?.filter(sticky => {
            let distance = getDistanceFromLatLonInKm(currentLocation?.lat,
                currentLocation?.lng,
                sticky?.stickeyAssignmentId?.latitude,
                sticky?.stickeyAssignmentId?.longitude
            )
            if (distance <= 20) {
                return true
            } else {
                return false
            }
        })
        setAllSticky(stickiesOnLocation)
    }, [isLoading, getSticky, currentLocation])

    const openModal = () => {
        setShowModal(!showModal);
    };


    const [module, setModule] = useState()
    const [lesson, setLesson] = useState()
    const [clessonId, setCLessonId] = useState('')

    let nextSkillId = null

    useEffect(() => {

        userData?.map((item) => {
            if (item?.id == moduleId) {
                setModule(item?.name)
            }
            item?.lessons?.map((lesson) => {
                if (lesson?.id == lessonId) {
                    setLesson(lesson?.name)
                    setCLessonId(lesson?.id)
                }
                lesson?.skills?.map((skill, index) => {
                    if (skill?.id == skillId) {
                        if (nextSkillId == null) {
                            nextSkillId = lesson?.skills[index + 1]?.id
                        }
                    }
                })
            })
        })


    }, [])


    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(
            window.innerWidth < 1000
        );
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(
            window.innerWidth < 1000
        );
    }, [window.innerWidth, window.innerHeight, window.orientation]);



    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [])
    setTimeout(() => {
        setShowLoader(false)
    }, 3000);

    if (isMobile) {
        return (<TurnPhoneV />)
    }
    else if (loading || modules === undefined) {
        return (<>
            {showLoader && <Loading />}
            {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
        </>
        )
    } else
        return (
            <>
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <div className="mysidebar">
                    <SideBar nextLink={nextLink} setNextLink={setNextLink} page="Skills" />
                </div>
                <RewardsModal open={open} setOpen={setOpen} />
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
                <div className="skills-container skill-redesign" style={{ overflow: "hidden", padding: "2rem" }}>
                    <Grid container>
                        <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem" }}>
                            <img src="/assets/svg/logo.svg" style={{ width: "180px" }} />
                        </Grid>
                        <Grid container xs={12} sm={4} justifyContent="flex-end">

                            <Grid item container justifyContent="flex-end">
                                {
                                    !!allSticky?.length &&
                                    <Link to="/sticky">
                                        <Button
                                            id="basic-button"
                                        >
                                            <img src="/assets/icons/notification-icon.svg" alt="" style={{ width: '37px' }} />
                                        </Button>
                                    </Link>
                                }
                                <Link
                                    to="/wordscape"
                                    onClick={() =>
                                        log({
                                            pageName: "Skill Details Assess",
                                            buttonName: "Word Game",
                                        })
                                    }
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}
                                >
                                    <div style={{ display: 'flex', padding: '0.5rem' }} className="wordscape__container">
                                        <img src="../assets/svg/main-home/word-game.svg" style={{ width: "35px", marginRight: "1rem" }} alt="" />
                                        <h1 className="lessonheading">Wordle</h1>
                                    </div>
                                </Link>

                                <div className="logoutIcon" onClick={() => {
                                    if (sessionStorage.getItem("access") === "ok") {
                                        Swal.fire({
                                            title: '',
                                            text: "It's a teacher view, so you can't logout",
                                            icon: 'info',
                                            confirmButtonText: 'OK'
                                        })
                                        return
                                    }
                                    dispatch(emptyStore())
                                    localStorage.removeItem("token")
window.location.reload()
                                    log({
                                        pageName: 'Module',
                                        buttonName: 'Logout Button'
                                    })
                                    navigate("/get-started")
                                }}>
                                    <div className="logged">
                                        <div class="block">
                                            <span className="animatedIcony">
                                                <div class="div1" >
                                                    <img src="/assets/img/logout1.png" />
                                                    {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                </div>
                                                <div class="rect">
                                                    <img src="/assets/img/logout.png" />
                                                </div>
                                                <div class="div2">
                                                    <img src="/assets/img/logout1.png" />
                                                    {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Notifications />
                            </Grid>
                        </Grid>
                    </Grid>

                    <div className="myrow" style={{ marginBottom: "0" }}>
                        <div className="text-heading">
                            <div className="row d-flex justify-content-center mt-1" style={{ padding: "10px 20px" }}>
                                <Link
                                    to={`/lesson?id=${moduleId}`}
                                    className="mybtn customizedbtn"
                                    style={{ background: "transparent" }}
                                >
                                    <img src="assets/img/arrowback.png" style={{ paddingRight: ".5rem" }} />
                                    Back
                                </Link>
                            </div>
                        </div>

                        <div className="translate-buttons">

                            <Link to="/" className="translate-it"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTranslate(true)
                                    log({
                                        pageName: 'Alt Space-vr',
                                        buttonName: 'Translate It'
                                    })
                                }}>
                                Translate It
                            </Link>

                            <Link
                                to="/rewards"
                                className="completed-lessons"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpen(true);
                                    log({
                                        pageName: "Lesson",
                                        buttonName: "Rewards",
                                    });
                                }}
                            >
                                <span>
                                    {user?.earnPoints?.toLocaleString()} / {user?.totalPoints?.toLocaleString()}
                                </span>{" "}
                                <LocalAtmRoundedIcon />
                            </Link>
                        </div>
                    </div>

                    <Grid container xs={12} justifyContent="space-between" sx={{ paddingLeft: "2rem" }}>
                        <Grid container item xs={12} sm={12} md={6} direction="column">

                            {/* <Typography variant="h1" className="subskill__lessonhead2">{lesson && lesson}</Typography> */}
                        </Grid>
                    </Grid>

                    <Grid container xs={12} className="subskillsredesign__subscontain" sx={{ flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'scroll', width: '87vw' }}>


                        <Grid item className="subskillsredesign__subscontaininner">
                            <Grid container gap={"1rem"} sx={{ flexWrap: 'nowrap' }}>
                                {
                                    modules?.filter(module => { return (module.id == moduleId) })[0]?.lessons?.filter(lesson => { return (lesson.id == lessonId) })[0]?.skills?.filter(skil => { return (skil.id == skillId) })[0]?.subSkills?.map((skill, index) => {
                                        return (<>
                                            <SkillOne moduleId={moduleId} lessonId={lessonId} skill={skill} skillId={skillId} keyIndex={index} />
                                        </>)
                                    })
                                }
                                {
                                    modules?.filter(module => { return (module.id == moduleId) })[0]?.lessons?.filter(lesson => { return (lesson.id == lessonId) })[0]?.skills?.filter(skil => { return (skil.id == skillId) })[0]?.subSkills?.length === 0 &&
                                    <div className="col-4 d-flex justify-content-center align-items-center">
                                        <img width="100%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>



                    {/* <Link className="prev" to={`/lesson?id=${moduleId}`}
                        onClick={() => log({
                            pageName: 'Skills',
                            buttonName: 'Prev'
                        })}>
                        <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                    </Link> */}




                    {
                        nextSkillId &&
                        <Link className="next" to={`/skills?moduleId=${moduleId}&lessonId=${lessonId}&skillId=${nextSkillId}`} onClick={() => log({
                            pageName: 'Skills',
                            buttonName: 'Prev'
                        })}>
                            <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                        </Link>
                    }
                    {/* <div style={{ position: "relative", }}> */}
                    <ChatBot pageName={"SubSkills"} data={modules?.filter(module => { return (module.id == moduleId) })[0]?.lessons?.filter(lesson => { return (lesson.id == lessonId) })[0]?.skills?.filter(skil => { return (skil.id == skillId) })[0]?.subSkills?.map(sub => { return ({ name: sub.name, description: sub.description }) })} />
                    {/* <div className="chat-btn" style={{ right: '30px', cursor:"pointer" }}
                            onClick={() => {log({
                                pageName: 'Skills', 
                                buttonName: 'Chat'
                            });
                            setChatBotModal(true)}}>
                            <img src="../../assets/svg/main-home/chat-icon.svg" alt="" />
                        </div>
                    </div> */}
                    {
                        sessionStorage.getItem('access') !== 'ok' &&
                        <button
                            onClick={openModal}
                            // className="btn-journal"
                            style={journalButtonStyle}
                        >
                            {
                                showModal ?
                                    <CloseIcon fontSize="large" />
                                    :
                                    <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#616ded', marginTop: '-2px' }} icon={faBook} />
                            }
                        </button>
                    }
                    <GoalsModal />
                    <TodoListModal />
                </div>
            </>
        )
}


const journalButtonStyle = {
    position: 'fixed',
    width: 50,
    height: 50,
    padding: 7,
    right: 30,
    bottom: 210,
    background: '#fff',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '50%'
}